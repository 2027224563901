import { useState, useEffect } from 'react';
import {
	Button,
	Text,
	Stack,
	Image,
	Box,
	Grid,
	useMantineTheme,
} from '@mantine/core';
import { IMeeting } from '../../../../interfaces/meeting';
import { createVideoThumbnail } from '../../../../helpers/data';
import { useSetRecoilState } from 'recoil';
import { currentMeeting, myMeetings } from '../../../../Atoms/meetingAtom';
import { updateMeeting } from '../../../../api/api';
import { currentUserReelays } from '../../../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';
import { MILLISECONDS_PER_SECOND } from '../../../../components/constants';

interface Props {
	meeting: IMeeting;
	screenshareHistory: { botJoinTime: number; created_at: string }[];
}

export default function ThumbnailSetter({
	meeting,
	screenshareHistory,
}: Props) {
	const theme = useMantineTheme();
	const { videoMetadata, thumbnailTime } = meeting;
	const setMyMeetings = useSetRecoilState(myMeetings);
	const setCurrentUserReelays = useSetRecoilState(currentUserReelays);
	const setCurrentMeeting = useSetRecoilState(currentMeeting);

	const [selectedThumbnail, setSelectedThumbnail] = useState(thumbnailTime);

	const saveThumbnail = async () => {
		try {
			// save video thumbnail in seconds
			const response = await updateMeeting(
				{ thumbnailTime: selectedThumbnail },
				meeting.id,
				meeting.organizationID
			);
			const updatedMeeting = response?.data?.data;
			setMyMeetings((oldMeetings) =>
				oldMeetings.map((oldMeeting) =>
					oldMeeting.id === updatedMeeting.id ? updatedMeeting : oldMeeting
				)
			);
			setCurrentUserReelays((oldMeetings: IMeeting[]) =>
				oldMeetings.map((oldMeeting: IMeeting) =>
					oldMeeting.id === updatedMeeting.id ? updatedMeeting : oldMeeting
				)
			);
			setCurrentMeeting(updatedMeeting);
			showSuccessNotification({
				message: 'Meeting was successfully updated! Great work!',
			});
		} catch (error) {
			logger('error', 'error updating meeting', error);
			showFailureNotification({
				message: 'There was an error updating the meeting. Please try again.',
			});
		}
	};

	const calculateTimeInSeconds = (botJoinTime: number, createdAt: string) => {
		const createdAtDate = new Date(createdAt).getTime();
		return Math.floor((createdAtDate - botJoinTime) / MILLISECONDS_PER_SECOND);
	};

	const thumbnails = screenshareHistory.map((entry) => {
		const timeInSeconds = calculateTimeInSeconds(
			entry.botJoinTime,
			entry.created_at
		);
		return {
			timeInSeconds,
			thumbnail: createVideoThumbnail(videoMetadata.playbackID, timeInSeconds),
		};
	});

	useEffect(() => {
		// Ensure the correct thumbnail is preselected based on thumbnailTime
		const preselectedThumbnail = thumbnails.find(
			(thumbnail) => thumbnail.timeInSeconds === thumbnailTime
		);
		if (preselectedThumbnail) {
			setSelectedThumbnail(preselectedThumbnail.timeInSeconds);
		}
	}, [thumbnailTime]);

	return (
		<Stack align={'flex-start'}>
			<Text size={'sm'} color={'primary-text'}>
				You can change the thumbnail of this video by selecting one of the
				images below.
			</Text>
			<Grid>
				{thumbnails.map((thumbnailData, index) => (
					<Grid.Col span={6} key={index}>
						<Box
							onClick={() => setSelectedThumbnail(thumbnailData.timeInSeconds)}
							sx={{
								border:
									selectedThumbnail === thumbnailData.timeInSeconds
										? `4px solid ${theme.fn.primaryColor()}`
										: 'none',
								borderRadius: '8px',
								cursor: 'pointer',
							}}
						>
							<Image src={thumbnailData.thumbnail} />
						</Box>
					</Grid.Col>
				))}
			</Grid>
			<Button onClick={saveThumbnail}>Save Thumbnail</Button>
		</Stack>
	);
}
