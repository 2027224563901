import React, { useCallback, useEffect, useState } from 'react';
import './app.css';
import Layout from './components/appshell';
import { LoginPage } from './pages/loginpage';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import OrganizationManagement from './pages/admin/organization_management';
import EditOrganization from './pages/admin/edit_organization';
import Actions from './pages/actions';
import Meeting from './pages/meeting';
import MeetingManagement from './pages/admin/meeting_management';
import { ProtectedRoute } from './components/ProtectedRoute';
import { ProtectedAdminRoute } from './components/ProtectedAdminRoute';
import { UnauthorizedAccess } from './components/UnauthorizedAccess';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isAuthenticated, originalURL } from './Atoms/auth';
import { PageNotFound } from './components/PageNotFound';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router';
import { AuditPage } from './pages/admin/AuditPage';
import {
	ActionIcon,
	Button,
	createStyles,
	MantineProvider,
	Overlay,
	useMantineTheme,
} from '@mantine/core';
import { creatingHighlightAtom, user } from './Atoms/userAtoms';
import { logger } from './helpers/logger';
import { SignupPage } from './pages/signup';
import { ServerError } from './components/ServerError';
import { isAdmin, isSupportUser } from './helpers/auth';
import { ErrorBoundary } from 'react-error-boundary';
import Settings from './pages/settings';
import ShareableMeetingLink from './pages/ShareableMeetingLink';
import { CreateAccount } from './pages/CreateAccount';
import {
	organizationBrand,
	organizationSettings,
	originalFaviconHREF,
	userSettings,
} from './Atoms/settings';
import { buildThemeWithBrandColors } from './helpers/settings';
import { getOrganizationBrand, getOrganizationSettings } from './api/settings';
import TermsOfUseModal from './components/Modals/TermsOfUseModal';
import DecideAccessRedirect from './pages/DecideAccessRedirect';
import { debounce, getPageTitle } from './_utils/handy-functions';
import { MobileHostView } from './pages/mobileHostView';
import Collections from './pages/collections';
import MeetingsPage from './pages/meetings';
import CollectionPage from './pages/collections/components/CollectionPage';
import { useFlags } from 'flagsmith/react';
import { flagsmithFeatureFlags } from './components/constants';
import { createPusherInstance } from './helpers/pusher';
import {
	calendarStatusData,
	calendarSyncingData,
	integrations,
} from './Atoms/integrations';
import { useCollections } from './customHooks/useCollections';
import { getLiveMeetings } from './api/playPauseRecording';
import { liveMeetingsWithBots, upcomingMeetings } from './Atoms/recallAI';
import {
	getUserUpcomingMeetings,
	updateLiveMeetingsByBotEvent,
} from './helpers/recallai';
import { filestackUpload, getUserCalendars } from './api/api';
import LiveBots from './pages/admin/LiveBots';
import { addMobileCheck } from './helpers/mobile';
import CalendarIntegrationModal from './components/Modals/CalendarIntegrationModal';
import { DateTime } from 'luxon';
import MinutesPage from './pages/minutes';
import FilestackPicker from './components/FilestackPicker/FilestackPicker';
import {
	filestackModalOpenedAtom,
	filestackUploadingAtom,
	usingUploadMeetingButtonAtom,
} from './Atoms/filestack';
import {
	showFailureNotification,
	showSuccessNotification,
} from './helpers/notifications';
import { IMeeting } from './interfaces/meeting';
import { PickerFileMetadata, PickerResponse } from 'filestack-js';
import { reloadMeetingsAtom } from './Atoms/meetingAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons';
import Gleap from 'gleap';
import MassResyncPage from './pages/massresync';

const useStyles = createStyles(() => ({
	filestackCloseButton: {
		position: 'absolute',
		top: 10,
		right: 10,
		backgroundColor: 'transparent',
		border: 'none',
		cursor: 'pointer',
		fontSize: 20,
		zIndex: 21474836492343,
		color: 'black',
	},
}));

function App() {
	const { classes, theme } = useStyles();
	const location = useLocation();
	const setOriginalURL = useSetRecoilState(originalURL);
	const currentUser = useRecoilValue(user);
	const userSettingsData = useRecoilValue(userSettings);
	const [brand, setBrand] = useRecoilState(organizationBrand);
	const setOrganizationSettings = useSetRecoilState(organizationSettings);
	const [originalFaviconPath, setOriginalFaviconPath] =
		useRecoilState(originalFaviconHREF);
	const creatingHighlight = useRecoilValue(creatingHighlightAtom);
	const authenticated = useRecoilValue(isAuthenticated);
	const [liveMeetings, setLiveMeetings] = useRecoilState(liveMeetingsWithBots);
	const navigate = useNavigate();
	const [opened, { open, close }] = useDisclosure(false);
	const [
		calendarModalOpened,
		{ open: openCalendarModal, close: closeCalendarModal },
	] = useDisclosure(false);
	const { meetingCollections, actionItems } = flagsmithFeatureFlags;
	const flags = useFlags([meetingCollections, actionItems]);
	const collectionsEnabled = flags?.meeting_collections?.enabled;
	useCollections();
	const actionItemsEnabled = flags?.action_items?.enabled;
	const [calendarSyncing, setCalendarSyncing] =
		useRecoilState(calendarSyncingData);
	const [calendarStatus, setCalendarStatus] =
		useRecoilState(calendarStatusData);
	const setUpcomingMeetings = useSetRecoilState(upcomingMeetings);
	const setCalendarIntegrations = useSetRecoilState(integrations);
	const [filestackModalOpened, setFilestackModalOpened] = useRecoilState(
		filestackModalOpenedAtom
	);
	const [usingUploadMeetingButton, setUsingUploadMeetingButton] =
		useRecoilState(usingUploadMeetingButtonAtom);
	const [filestackUploading, setFilestackUploading] = useRecoilState(
		filestackUploadingAtom
	);
	const [reloadMeetings, setReloadMeetings] =
		useRecoilState(reloadMeetingsAtom);
	const queryParams = new URLSearchParams(location.search);
	const guestViewToken = queryParams.get('vt');

	useEffect(() => {
		if (authenticated && location.pathname === '/') {
			if (isSupportUser(currentUser.roles)) navigate('/admin/meetings');
			else navigate('/meetings');
		} else if (
			!authenticated &&
			location.pathname !== '/login' &&
			!guestViewToken
		) {
			navigate('/login');
		}
		// if pathname is /share/ then add a flag createAccount=true to the URL.
		const createAccountParam =
			location.pathname === '/share/' ? '&createAccount=true' : '';
		setOriginalURL(location.pathname + location.search + createAccountParam);

		logger('warn', 'App component rendered via logger helper function', {
			name: 'app-component-rendered',
			component: 'App',
		});

		// setup pusher instance on window obj and subscribe to channel
		createPusherInstance();
		// add mobile check to window obj
		addMobileCheck();

		const channelName = `${
			currentUser?.id
				? `${currentUser.id}-${currentUser.currentOrganizationID}`
				: currentUser?.currentOrganizationID
		}`;

		const channel = window?.pusher?.subscribe(channelName);

		const debouncedUpdateMeetings = debounce(async () => {
			const upcomingMeetings = await getUserUpcomingMeetings(
				currentUser.currentOrganizationID
			);
			setUpcomingMeetings(upcomingMeetings);
			setReloadMeetings((prev) => !prev);
		}, 3000); // Adjust the delay as needed

		channel.bind('calendar:syncing', async (data) => {
			try {
				setCalendarSyncing((prev) => ({
					...prev,
					[data?.calendarID]: data?.isSyncing,
				}));
				if (!data?.isSyncing) {
					debouncedUpdateMeetings();
				}
			} catch (error) {
				console.error('calendar:syncing error', error);
				setCalendarSyncing((prev) => ({
					...prev,
					[data?.calendarID]: data?.isSyncing,
				}));
				if (!data?.isSyncing) {
					debouncedUpdateMeetings();
				}
				showFailureNotification({
					message:
						'Sorry, there was a syncing error. Please refresh the page for updates.',
				});
			}
		});

		channel.bind('calendar:status', async (data) => {
			try {
				setCalendarStatus((prev) => ({
					...prev,
					[data?.calendarID]: data?.status,
				}));
				if (data?.status === 'connected') {
					const upcomingMeetings = await getUserUpcomingMeetings(
						currentUser.currentOrganizationID
					);
					setUpcomingMeetings(upcomingMeetings);
				}
			} catch (error) {
				console.error('calendar:status error', error);
				setCalendarStatus((prev) => ({
					...prev,
					[data?.calendarID]: data?.status,
				}));
				if (data?.status === 'connected') {
					const upcomingMeetings = await getUserUpcomingMeetings(
						currentUser.currentOrganizationID
					);
					setUpcomingMeetings(upcomingMeetings);
				}
			}
		});

		// channel.bind('meeting:paused', (data) => {
		// 	console.log('meeting:paused data', data);
		// 	//
		// });
		// channel.bind('meeting:resumed', (data) => {
		// 	console.log('meeting:resumed data', data);
		// });

		const debouncedMeetingEventHandler = debounce(async (data) => {
			try {
				const meeting = data?.meeting;
				const newLiveMeetings = updateLiveMeetingsByBotEvent(
					liveMeetings,
					meeting
				);
				setLiveMeetings(newLiveMeetings);
				const upcomingMeetings = await getUserUpcomingMeetings(
					currentUser.currentOrganizationID
				);
				setUpcomingMeetings(upcomingMeetings);
			} catch (error) {
				console.error('meeting:event error', error);
				const meeting = data?.meeting;
				const newLiveMeetings = updateLiveMeetingsByBotEvent(
					liveMeetings,
					meeting
				);
				setLiveMeetings(newLiveMeetings);
				const upcomingMeetings = await getUserUpcomingMeetings(
					currentUser.currentOrganizationID
				);
				setUpcomingMeetings(upcomingMeetings);
				// Since the logic in catch is the same as in try, consider handling the error differently or removing the redundancy.
			}
		}, 1000);

		channel.bind('meeting:event', debouncedMeetingEventHandler);

		if (isSupportUser(currentUser.roles)) {
			const supportChannelName = `reelay-support-users`;
			const supportChannel = window?.pusher?.subscribe(supportChannelName);

			supportChannel.bind('meeting:full:asset', async (data) => {
				showSuccessNotification({
					title: `Meeting ${data?.meeting?.name} has been successfully converted to a full asset!`,
					message: `You can now view the full asset in the meeting details page. If you are already viewing the meeting, please refresh the page to see the changes.`,
				});
			});

			supportChannel.bind('calendar-sync-logs', async (data) => {
				console.log('calendar-sync-logs data', data);
			});
		}
	}, [currentUser]);

	useEffect(() => {
		const refetchUpcomingAndLiveMeetings = async () => {
			try {
				const [res, upcomingMeetings] = await Promise.all([
					getLiveMeetings(currentUser.currentOrganizationID),
					getUserUpcomingMeetings(currentUser.currentOrganizationID),
				]);
				setLiveMeetings(res?.data || []);
				setUpcomingMeetings(upcomingMeetings);
			} catch (error) {
				logger('error', 'Error refetching upcoming and live meetings', error);
				const [res, upcomingMeetings] = await Promise.all([
					getLiveMeetings(currentUser.currentOrganizationID),
					getUserUpcomingMeetings(currentUser.currentOrganizationID),
				]);
				setLiveMeetings(res?.data || []);
				setUpcomingMeetings(upcomingMeetings);
			}
		};
		if (authenticated) refetchUpcomingAndLiveMeetings();
	}, [reloadMeetings]);

	useEffect(() => {
		const favicon = document.getElementById('favicon-logo') as HTMLLinkElement;
		const title = document.getElementById('title') as HTMLTitleElement;
		if (typeof brand === 'object' && Object.keys(brand).length) {
			if (!originalFaviconPath.length) setOriginalFaviconPath(favicon.href);
			if (brand?.name)
				title.text = `${getPageTitle(location.pathname)} | ${brand?.name}`;
			if (brand?.icon) favicon.href = brand?.icon;
		} else {
			title.text = `${getPageTitle(location.pathname)} | Reelay`;
			favicon.href = `${window.location.origin}/favicon.ico`;
		}
	}, [brand, location]);

	const colorsWithBrandedColors = buildThemeWithBrandColors(
		theme.colors,
		brand
	);

	useEffect(() => {
		if (currentUser?.currentOrganizationID) {
			getOrganizationBrand(currentUser.currentOrganizationID)
				.then((res) => {
					if (res) setBrand(res?.value);
				})
				.catch((err) => {
					logger('error', 'Error getting organization brand', err);
				});

			getOrganizationSettings(currentUser.currentOrganizationID)
				.then((res) => {
					const settings = res.reduce((acc, curr) => {
						if (curr.key !== 'brand') acc[curr.key] = curr.value;
						return acc;
					}, {});
					setOrganizationSettings(settings);
					// if (res) setBrand(res?.value);
				})
				.catch((err) => {
					logger('error', 'Error getting organization settings', err);
				});

			getLiveMeetings(currentUser.currentOrganizationID)
				.then((res) => {
					setLiveMeetings(res?.data || []);
				})
				.catch((err) => {
					logger('error', 'Error getting live meetings', err);
				});
		}
	}, [currentUser, userSettingsData]);

	useEffect(() => {
		const loadOrganizationSettingsAndCollections = async () => {
			if (currentUser?.currentOrganizationID) {
				getOrganizationBrand(currentUser.currentOrganizationID)
					.then((res) => {
						if (res) setBrand(res?.value);
					})
					.catch((err) => {
						logger('error', 'Error getting organization brand', err);
					});
			}
		};
		loadOrganizationSettingsAndCollections();
	}, [currentUser, userSettingsData]);

	// useEffect(() => {
	// 	const loadModals = async () => {};
	// 	loadModals();
	// }, [userSettingsData]);

	useEffect(() => {
		const loadCalendarModal = async () => {
			if (!authenticated) return;

			if (
				(!userSettingsData?.agreed_with_terms ||
					!userSettingsData?.time_zone) &&
				authenticated
			) {
				open();
				return;
			}

			const res = await getUserCalendars(currentUser.currentOrganizationID);
			const calendars = res.data.data;
			setCalendarIntegrations(calendars);
			const last_asked_calendar_sync = localStorage.getItem(
				'last_asked_calendar_sync'
			);

			if (
				calendars.length ||
				!currentUser?.roles?.includes('create-meeting') ||
				opened
			) {
				// clear local storage
				localStorage.removeItem('last_asked_calendar_sync');
				// return. No need to open calendar modal
				return;
			}

			const HOUR_LIMIT = 12; // Original time limit
			let askedCalendarSyncOverIntervalAgo = false;

			if (last_asked_calendar_sync) {
				const lastAskedTime = DateTime.fromISO(last_asked_calendar_sync);
				const now = DateTime.now();
				const diff = now.diff(lastAskedTime, 'hours').hours; // Original difference check for hours
				const timeExceeded = diff > HOUR_LIMIT;
				askedCalendarSyncOverIntervalAgo = timeExceeded;
				if (timeExceeded) {
					localStorage.setItem(
						'last_asked_calendar_sync',
						new Date().toISOString()
					);
				}
			} else {
				// if there is no
				localStorage.setItem(
					'last_asked_calendar_sync',
					new Date().toISOString()
				);
			}

			const shouldOpenCalendarModal =
				!calendars.length &&
				currentUser?.roles?.includes('create-meeting') &&
				!userSettingsData?.calendar_modal_dont_ask_again &&
				(!last_asked_calendar_sync || askedCalendarSyncOverIntervalAgo) &&
				userSettingsData?.agreed_with_terms &&
				Boolean(userSettingsData?.time_zone);

			if (shouldOpenCalendarModal) {
				openCalendarModal();
			}
		};
		loadCalendarModal();
	}, [userSettingsData]);

	const handleUploadStarted = useCallback((file: PickerFileMetadata) => {
		showSuccessNotification({
			title: 'Uploading files...',
			message:
				'Please wait while we upload your files. These may take a few minutes.',
		});
		setFilestackUploading(true);
	}, []);

	const handleUploadDone = useCallback(
		async ({ filesUploaded, filesFailed }: PickerResponse) => {
			try {
				if (filesUploaded.length) {
					for (const file of filesUploaded) {
						const response = await filestackUpload({
							organizationID: currentUser.currentOrganizationID,
							platform: 'uploaded',
							recapOnly: !usingUploadMeetingButton,
							name: file.filename,
							filestackData: {
								uploadID: file.uploadId,
								url: file.url,
							},
							fullAsset: usingUploadMeetingButton,
						});
						const newMeeting: IMeeting = response?.data?.data;
						setReloadMeetings((prev) => !prev);
						showSuccessNotification({
							title: 'Upload successful!',
							message: usingUploadMeetingButton
								? `Your meeting ${newMeeting.name} has been uploaded successfully. You will be able to view it once processing is complete.`
								: `Your minutes ${newMeeting.name} has been uploaded successfully. You will be able to download it once processing is complete.`,
						});
					}
				}
				if (filesFailed.length) {
					showFailureNotification({
						message: 'Failed to upload files',
					});
				}
				if (usingUploadMeetingButton) setUsingUploadMeetingButton(false);
			} catch (error) {
				console.error('handleUploadDone error', error);
			} finally {
				setFilestackUploading(false);
			}
		},
		[currentUser.currentOrganizationID, usingUploadMeetingButton]
	);

	const handleCloseFilestackModal = () => {
		setFilestackModalOpened(false);
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const shouldOpenGleap =
			urlParams.get('openSupport') === 'true' ||
			urlParams.get('opensupport') === 'true';
		if (shouldOpenGleap) {
			Gleap.openHelpCenter();
		}
	}, []);

	return (
		<ErrorBoundary
			FallbackComponent={ServerError}
			onReset={() =>
				navigate(isAdmin(currentUser.roles) ? '/admin/meetings' : '/meetings')
			}
		>
			<MantineProvider
				theme={{
					...colorsWithBrandedColors,
				}}
				inherit
			>
				<div className='app'>
					{/* Modals  */}
					<TermsOfUseModal
						opened={opened}
						close={close}
						// openCalendarModal={openCalendarModal}
					/>
					<CalendarIntegrationModal
						opened={calendarModalOpened}
						close={closeCalendarModal}
					/>
					{filestackModalOpened ? (
						<Overlay color='#000' opacity={0.75} />
					) : null}
					{filestackModalOpened ? (
						<div className='filestack-picker-wrapper'>
							<ActionIcon
								variant='transparent'
								className={classes.filestackCloseButton}
								onClick={handleCloseFilestackModal}
							>
								<FontAwesomeIcon
									icon={faX}
									size={'xs'}
									color='rgb(68, 68, 68)'
								/>
							</ActionIcon>
							<FilestackPicker
								handleUploadDone={handleUploadDone}
								handleUploadStarted={handleUploadStarted}
							/>
						</div>
					) : null}
					<Routes>
						<Route path='/login' element={<LoginPage />}>
							<Route path=':token' element={<LoginPage />} />
						</Route>
						<Route path='/signup' element={<SignupPage />} />
						<Route path='/new-account' element={<CreateAccount />} />
						<Route
							path=''
							element={
								<ProtectedRoute>
									<Layout />
								</ProtectedRoute>
							}
						>
							<Route path='admin'>
								<Route path={'meetings'}>
									<Route index element={<Navigate replace to='audit' />} />
									<Route
										path='audit'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'audit'} />
											</ProtectedAdminRoute>
										}
									/>
									<Route
										path='draft'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'draft'} />
											</ProtectedAdminRoute>
										}
									/>
									<Route
										path='review'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'review'} />
											</ProtectedAdminRoute>
										}
									/>
									<Route
										path='scheduled'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'scheduled'} />
											</ProtectedAdminRoute>
										}
									/>
									<Route
										path='all'
										element={
											<ProtectedAdminRoute>
												<MeetingManagement segmentValue={'all'} />
											</ProtectedAdminRoute>
										}
									/>
								</Route>
								<Route path='edit/:meetingID' element={<AuditPage />} />
								<Route path='organizations'>
									<Route index element={<OrganizationManagement />} />
									<Route path='edit/:organizationID'>
										<Route index element={<Navigate replace to='audit' />} />
										<Route
											path='audit'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'audit'} />
												</ProtectedAdminRoute>
											}
										/>
										<Route
											path='draft'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'draft'} />
												</ProtectedAdminRoute>
											}
										/>
										<Route
											path='review'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'review'} />
												</ProtectedAdminRoute>
											}
										/>
										<Route
											path='scheduled'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'scheduled'} />
												</ProtectedAdminRoute>
											}
										/>
										<Route
											path='all'
											element={
												<ProtectedAdminRoute>
													<EditOrganization segmentValue={'all'} />
												</ProtectedAdminRoute>
											}
										/>
									</Route>
								</Route>
								<Route path='live'>
									<Route index element={<LiveBots />} />
								</Route>
								<Route path={'secret-resync'} element={<MassResyncPage />} />
							</Route>

							{/* client side */}
							<Route path='meetings'>
								<Route index element={<MeetingsPage segmentValue='live' />} />
								<Route
									path='recordings'
									element={
										<MeetingsPage segmentValue='live' recapOnly={false} />
									}
								/>
								<Route
									path='schedule'
									element={<MeetingsPage segmentValue='upcoming' />}
								/>
								<Route
									path='minutes'
									element={
										<MinutesPage
											buttonLoading={filestackUploading}
											setButtonLoading={setFilestackUploading}
										/>
									}
								/>
								<Route path=':meetingID' element={<Meeting />} />
								<Route path=':meetingID/:tabValue' element={<Meeting />} />
							</Route>
							<Route path='users/:userID' element={<MobileHostView />} />
							{actionItemsEnabled ? (
								<Route path='actions'>
									<Route index element={<Actions segmentValue='todo' />} />
									<Route
										path='todo'
										index
										element={<Actions segmentValue='todo' />}
									/>
									<Route
										path='done'
										element={<Actions segmentValue='done' />}
									/>
								</Route>
							) : null}
							<Route path='/settings' element={<Settings />} />
							{collectionsEnabled ? (
								<Route path='/collections'>
									<Route index element={<Collections />} />
									<Route path=':collectionID' element={<CollectionPage />} />
									<Route path='view/:meetingID' element={<Meeting />} />
									<Route path=':meetingID/view' element={<Meeting />} />
								</Route>
							) : null}
							<Route path='/settings/:tabValue' element={<Settings />} />
							{/* <Route path='/account' element={<AccountSettings />} /> */}
							<Route path='/unauthorized' element={<UnauthorizedAccess />} />
							<Route path='/share' element={<ShareableMeetingLink />} />
							<Route
								path='/access-request'
								element={<DecideAccessRedirect />}
							/>
							<Route path='*' element={<PageNotFound />} />
						</Route>
					</Routes>
				</div>
			</MantineProvider>
		</ErrorBoundary>
	);
}

export default App;
