import {
	createStyles,
	Paper,
	Table,
	Text,
	Checkbox,
	Stack,
	Loader,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { StatusBadge } from '../../../../../components/Badges/StatusBadge';
import { IMeeting } from '../../../../../interfaces/meeting';
import PlatformIcon from '../../../../../components/PlatformIcon';
import TableRowSkeleton from '../../../../../components/Skeletons/TableRowSkeleton';
import { getMeetingHost } from '../../../../../helpers/meetings';

const enabledMeetingStatuses = ['review', 'published'];
const isAbleToOpenMeeting = (status: string) =>
	enabledMeetingStatuses.includes(status);

const useStyles = createStyles(() => ({
	header: {
		height: '45px',
		alignContent: 'center',
	},
	paperClip: {
		cursor: 'pointer',
	},
}));

interface Props {
	meetings: IMeeting[];
	searchValue: string;
	rowsPerPage: number;
	meetingCount: number;
	fetchingMeetings: boolean;
	selection: string[];
	setSelection: React.Dispatch<React.SetStateAction<string[]>>;
	loading: boolean;
}

export default function AddMeetingToCollectionTable({
	meetings,
	loading,
	selection,
	setSelection,
}: Props) {
	const { classes } = useStyles();
	const toggleRow = (id: string) =>
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);
	const toggleAll = () =>
		setSelection((current) =>
			current.length === meetings.length ? [] : meetings.map((item) => item.id)
		);

	const ths = (
		<tr>
			<th>
				<Checkbox
					onChange={toggleAll}
					checked={selection.length === meetings.length}
					indeterminate={
						selection.length > 0 && selection.length !== meetings.length
					}
					size={'xs'}
				/>
			</th>
			<th>Meeting Name</th>
			<th>Hosted By</th>
			<th>Meeting Date</th>
			<th>Status</th>
			<th>Platform</th>
		</tr>
	);

	const rows = meetings.map((meeting: IMeeting) => {
		const {
			id,
			name,
			author,
			organizer,
			botMetadata,
			status,
			platform,
			friendlyID,
		} = meeting;

		const hostName = getMeetingHost(organizer, author, 'Unknown Author');

		return (
			<tr key={id}>
				<td>
					<Checkbox
						size={'xs'}
						checked={selection.includes(id)}
						onChange={() => toggleRow(id)}
					/>
				</td>
				<td>
					{isAbleToOpenMeeting(status) ? (
						<Link
							to={`/meetings/${friendlyID}`}
							style={{ color: 'black', textDecoration: 'none' }}
						>
							<Text weight={600}>{name}</Text>
						</Link>
					) : (
						<Text weight={600}>{name}</Text>
					)}
				</td>
				<td> {hostName} </td>
				<td>
					{status === 'scheduled'
						? DateTime.fromISO(botMetadata?.joinAt).toFormat('MM/dd/yyyy h:mma')
						: 'N/A'}
				</td>
				<td>
					<StatusBadge status={status} botStatus={botMetadata?.status} />
				</td>
				<td>
					<PlatformIcon platform={platform} />
				</td>
			</tr>
		);
	});

	const LoadingIndication = () => (
		<Stack align='center' w={'100%'} spacing={'sm'}>
			<Loader variant='bars' />
			<Text>Loading...</Text>
		</Stack>
	);

	return (
		<>
			{meetings.length ? (
				<Paper radius='md' shadow='md' withBorder>
					<Table captionSide='bottom' striped>
						<thead className={classes.header}>{ths}</thead>
						<tbody>
							{loading
								? Array.from({ length: 20 }, (_, index) => (
										<TableRowSkeleton key={index} colSpan={6} />
								  ))
								: rows}
						</tbody>
					</Table>
				</Paper>
			) : (
				<Text color={'reelay-secondary-dark'}>No meetings found.</Text>
			)}
		</>
	);
}
