import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const pauseRecallBotRecording = (
	botID: string,
	organizationID: string
) => {
	return axiosInstance
		.post(
			`v1/recall-ai/bot/pause-recording`,
			{
				botID,
			},
			{
				headers: { 'x-organization-id': organizationID },
			}
		)
		.then((res) => {
			logger('info', 'Response received from pauseRecallBotRecording', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error pauseRecallBotRecording', err);
			throw new Error(err?.response?.data);
		});
};

export const resumeRecallBotRecording = (
	botID: string,
	organizationID: string
) => {
	return axiosInstance
		.post(
			`v1/recall-ai/bot/resume-recording`,
			{
				botID,
			},
			{
				headers: { 'x-organization-id': organizationID },
			}
		)
		.then((res) => {
			logger('info', 'Response received from resumeRecallBotRecording', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error resumeRecallBotRecording', err);
			throw new Error(err?.response?.data);
		});
};

/**
 * This function is used to get the live meetings with recall ai bots recording in them.
 * @param organizationID string
 * @returns
 */
export const getLiveMeetings = (organizationID: string) => {
	return axiosInstance
		.get(`v1/recall-ai/bot/live-meetings`, {
			headers: { 'x-organization-id': organizationID },
		})
		.then((res) => {
			logger('info', 'Response received from getLiveMeetings', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error getLiveMeetings', err);
			throw new Error(err?.response?.data);
		});
};

/**
 * This function is used to get all the live meetings with recall ai bots recording in them.
 * @param organizationID string
 * @returns
 */
export const getAllLiveMeetings = (organizationID: string) => {
	return axiosInstance
		.get(`v1/recall-ai/bot/all-live-meetings`, {
			headers: { 'x-organization-id': organizationID },
		})
		.then((res) => {
			logger('info', 'Response received from getAllLiveMeetings', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error getLiveMeetings', err);
			throw new Error(err?.response?.data);
		});
};
