import React, { useState } from 'react';
import {
	createStyles,
	Paper,
	Table,
	Text,
	Menu,
	Checkbox,
	ActionIcon,
	Group,
	UnstyledButton,
	Center,
	rem,
	ScrollArea,
	Avatar,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEllipsisVertical,
	faTrash,
	faCheck,
	faUserPen,
	faSquare,
	faEnvelope,
} from '@fortawesome/pro-light-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	editScheduledMeetingInitialValues,
	editScheduledMeetingModalOpen,
	meetingSegmentedControlValue,
	previousPageURL,
	uploadingVideoAtom,
} from '../../../../Atoms/meetingAtom';
import { currentCollection } from '../../../../Atoms/collections';
import {
	IconSelector,
	IconChevronDown,
	IconChevronUp,
} from '@tabler/icons-react';
import classes from './ActionTable.module.css';
import { IMeeting } from '../../../../interfaces/meeting';
import TableRowSkeleton from '../../../../components/Skeletons/TableRowSkeleton';
import { hasCalendar } from '../../../../Atoms/integrations';
import { useCollections } from '../../../../customHooks/useCollections';
import {
	currentUser,
	isProjectManagerAtom,
	userOrganizationMembers,
} from '../../../../Atoms/userAtoms';
import { flagsmithFeatureFlags } from '../../../../components/constants';
import { useFlags } from 'flagsmith/react';
import { IAction } from '../../../../interfaces/action';
import { ActionRowData } from './helper';
import AvatarGroup from '../../../../components/AvatarGroup';
import { actionTableSelection } from '../../../../Atoms/actions';
import { isAssignedToUser } from '../../../../helpers/actions';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';
import {
	destroyMeetingHighlights,
	markActionItems,
	sendActionReminderEmail,
} from '../../../../api/api';
import { swapBackgroundColor } from '../../../../_utils/handy-functions';
import { getEnvForGleap } from '../../../../_utils/trackers';
import { getMeetingHost } from '../../../../helpers/meetings';
import { isMeetingOwnerOrProjectManager } from '../../../../helpers/auth';

export const VIDEO_UPLOAD_COMPLETED_PERCENTAGE = 100;
interface ThProps {
	children: React.ReactNode;
	reversed: boolean;
	sorted: boolean;
	onSort(): void;
}

function Th({
	children,
	reversed,
	sorted,
	onSort,
	width,
}: ThProps & { width?: string }) {
	const Icon = sorted
		? reversed
			? IconChevronUp
			: IconChevronDown
		: IconSelector;
	return (
		<th className={classes.th} style={{ width }}>
			<UnstyledButton onClick={onSort} className={classes.control}>
				<Group position='apart' noWrap>
					<Text fw={500} fz='sm'>
						{children}
					</Text>
					<Center className={classes.icon}>
						<Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
					</Center>
				</Group>
			</UnstyledButton>
		</th>
	);
}

const useStyles = createStyles(() => ({
	header: {
		height: '45px',
		alignContent: 'center',
	},
	paperClip: {
		cursor: 'pointer',
	},
}));

interface Props {
	actions: IAction[];
	setActions: React.Dispatch<React.SetStateAction<IAction[]>>;
	searchValue: string;
	rowsPerPage: number;
	meetingCount: number;
	fetchingMeetings: boolean;
	viewType: string;
	refresh: () => void;
	changeSort?: (value: string) => void;
	handleAssign: (action: IAction) => void;
}

export default function ActionsTable({
	actions,
	fetchingMeetings,
	refresh,
	changeSort,
	handleAssign,
}: Props) {
	const { classes, theme } = useStyles();
	const location = useLocation();
	const user = useRecoilValue(currentUser);
	const isPM = useRecoilValue(isProjectManagerAtom);
	const [selection, setSelection] = useRecoilState(actionTableSelection);
	const setPreviousURL = useSetRecoilState(previousPageURL);
	const orgUsers = useRecoilValue(userOrganizationMembers);

	const formatDeadlineTime = (isoString: string) => {
		return DateTime.fromISO(isoString).toLocaleString(DateTime.DATETIME_MED);
	};

	// table sort
	const [sortBy, setSortBy] = useState<keyof ActionRowData | null>(null);
	const [reverseSortDirection, setReverseSortDirection] = useState(false);

	// this should refire searchMeetings and update the "order" param accordingly.
	const setSorting = (field: keyof ActionRowData) => {
		const reversed = field === sortBy ? !reverseSortDirection : false;
		setReverseSortDirection(reversed);
		setSortBy(field);
		// %2B is "+" in URL encoding, and "-" is "%2D
		const order = `${reversed ? '-' : '%2B'}${String(field)}`;
		changeSort(order);
	};

	const deleteAction = async (action: IAction) => {
		const { id, meetingID } = action;
		try {
			const payload = [id];
			const res = await destroyMeetingHighlights(
				meetingID,
				payload,
				user.currentOrganizationID
			);
			const deletedActionIDs = res.data.data.meetingHighlightIDs;
			// refire the actions get request.
			refresh();
			showSuccessNotification({
				message: 'Action was successfully destroyed! Great work!',
			});
		} catch (err) {
			logger('error', 'error deleting moment', err);
			showFailureNotification({
				message:
					"We apologize, but there was an error while deleting the moment. Please try again, and if the issue persists, don't hesitate to contact our support team for assistance.",
			});
		}
	};

	const remindAction = async (action: IAction) => {
		// send API call to remind the action
		const { id, meetingID } = action;
		try {
			const res = await sendActionReminderEmail(
				[id],
				meetingID,
				user.currentOrganizationID
			);
			// refire the actions get request.
			showSuccessNotification({
				message: 'Action was successfully reminded! Great work!',
			});
		} catch (err) {
			logger('error', 'error deleting moment', err);
			showFailureNotification({
				message:
					"We apologize, but there was an error while deleting the moment. Please try again, and if the issue persists, don't hesitate to contact our support team for assistance.",
			});
		}
	};

	const markAsDone = async (action: IAction, isDone: boolean) => {
		const { id, meetingID } = action;
		try {
			const actionableStatus = isDone ? 'done' : 'todo';
			const res = await markActionItems(
				[id],
				actionableStatus,
				meetingID,
				user.currentOrganizationID
			);
			const meetingActionID = res?.data?.data?.meetingActionIDs[0];

			if (meetingActionID) {
				refresh();
				showSuccessNotification({
					message: `Action marked as ${actionableStatus}. Great job!`,
				});
			}
		} catch (err) {
			logger('error', 'error marking action', err);
			showFailureNotification({
				message:
					"Sorry, we were unable to update the action's status. Please try again or contact our support team for assistance.",
			});
		}
	};

	const toggleRow = (index: number) =>
		setSelection((current) =>
			current.includes(index)
				? current.filter((item) => item !== index)
				: [...current, index]
		);
	const toggleAll = () =>
		setSelection((current) =>
			current.length === actions.length ? [] : actions.map((item, idx) => idx)
		);

	const ths = (
		<tr>
			<th>
				<Checkbox
					onChange={toggleAll}
					checked={selection.length === actions.length && selection.length > 0}
					indeterminate={
						selection.length > 0 && selection.length !== actions.length
					}
					size={'xs'}
				/>
			</th>
			<Th
				sorted={sortBy === 'content'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('content')}
			>
				Action
			</Th>
			<Th
				sorted={sortBy === 'meetingName'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('meetingName')}
				width='200px'
			>
				Meeting
			</Th>
			<Th
				sorted={sortBy === 'meetingDate'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('meetingDate')}
				width='200px'
			>
				Date
			</Th>
			<Th
				sorted={sortBy === 'meetingHost'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('meetingHost')}
				width='200px'
			>
				Host
			</Th>
			<Th
				sorted={sortBy === 'assignedTo'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('assignedTo')}
				width='150px'
			>
				Assigned To
			</Th>
			<Th
				sorted={sortBy === 'assignedBy'}
				reversed={reverseSortDirection}
				onSort={() => setSorting('assignedBy')}
				width='150px'
			>
				Assigned By
			</Th>
			<th />
		</tr>
	);

	console.log('actions', actions);
	const rows = actions.map((action: IAction, index) => {
		const { id, actionableStatus, content, meeting, assignees = [] } = action;
		const { organizer, author, owningUserID } = meeting;
		const isMeetingHostOrPM = isMeetingOwnerOrProjectManager(
			organizer,
			owningUserID,
			user.id,
			user.roles
		);
		const assignedToYou = isAssignedToUser(assignees, user.id);
		const host = organizer?.userID === author?.id ? author : organizer;
		const assigners = assignees
			.map((assignee) => assignee.assigner)
			.filter((assigner) => Boolean(assigner))
			.filter(
				(assigner, index, self) =>
					self.findIndex((a) => a.id === assigner.id) === index
			);

		return (
			<tr key={id} id={`VIS_ctf9nfdtmsxfgg2vmcgg_${getEnvForGleap()}`}>
				<td>
					<Checkbox
						size={'xs'}
						checked={selection.includes(index)}
						onChange={() => toggleRow(index)}
					/>
				</td>
				<td
					style={{
						width: '30%',
					}}
				>
					<Link
						to={`/meetings/${action.meeting.friendlyID}/summary?tab=action&actionID=${action.id}`}
						onClick={() => setPreviousURL(location.pathname)}
						style={{ color: 'black', textDecoration: 'none' }}
					>
						<Text weight={600}>{action.content}</Text>
					</Link>
				</td>
				<td>{meeting.name || 'N/A'}</td>
				<td>
					{meeting?.meetingDate
						? formatDeadlineTime(meeting?.meetingDate)
						: 'N/A'}
				</td>
				<td>
					{host ? (
						<Group noWrap spacing={'sm'}>
							{host['userAvatarURL'] ? (
								<Avatar
									src={swapBackgroundColor(
										`${host['userAvatarURL']}-196.png`,
										theme.fn.primaryColor()
									)}
									radius='xl'
									size={34}
								/>
							) : (
								<Avatar
									color={'primary'}
									size={34}
									variant={'filled'}
									src={''}
									radius={'xl'}
									children={
										<Center>
											{host.firstName && host.lastName
												? `${host?.firstName?.substring(
														0,
														1
												  )}${host?.lastName?.substring(0, 1)}`.toUpperCase()
												: '?'}
										</Center>
									}
								/>
							)}
							{host.firstName} {host.lastName}
						</Group>
					) : (
						'N/A'
					)}
				</td>
				<td>
					<AvatarGroup users={assignees} />
				</td>
				<td>
					<AvatarGroup users={assigners} />
				</td>
				<td id={`VIS_ctf9nfdtmsxfgg2vmctg_${getEnvForGleap()}`}>
					<Group position={'right'}>
						<Menu shadow='md' width={275} position='left' withArrow>
							<Menu.Target>
								<ActionIcon onClick={(e) => e.stopPropagation()}>
									<FontAwesomeIcon
										icon={faEllipsisVertical}
										size={'sm'}
										style={{
											color: theme.colors['secondary-text'][0],
											cursor: 'pointer',
										}}
									/>
								</ActionIcon>
							</Menu.Target>

							<Menu.Dropdown>
								{isMeetingHostOrPM ? (
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													color: theme.colors['secondary-text'][0],
													cursor: 'pointer',
												}}
												icon={faUserPen}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											handleAssign(action);
										}}
									>
										Assign
									</Menu.Item>
								) : null}

								{isMeetingHostOrPM || assignedToYou ? (
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													color: theme.colors['secondary-text'][0],
													cursor: 'pointer',
												}}
												icon={actionableStatus === 'todo' ? faCheck : faSquare}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											markAsDone(action, actionableStatus === 'todo');
										}}
									>
										{actionableStatus === 'todo'
											? 'Mark as done'
											: 'Mark as todo'}
									</Menu.Item>
								) : null}

								{isMeetingHostOrPM ? (
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													color: theme.colors['secondary-text'][0],
													cursor: 'pointer',
												}}
												icon={faEnvelope}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											remindAction(action);
										}}
									>
										Remind
									</Menu.Item>
								) : null}

								{isMeetingHostOrPM ? (
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													color: theme.colors['secondary-text'][0],
													cursor: 'pointer',
												}}
												icon={faTrash}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											deleteAction(action);
										}}
									>
										Delete
									</Menu.Item>
								) : null}
							</Menu.Dropdown>
						</Menu>
					</Group>
				</td>
			</tr>
		);
	});

	return (
		<Paper radius='md' shadow='md' withBorder h={'100%'}>
			<ScrollArea
				h={'100%'}
				// onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
			>
				<Table captionSide='bottom' striped highlightOnHover>
					<thead className={classes.header}>{ths}</thead>
					<tbody>
						{fetchingMeetings
							? Array.from({ length: 20 }, (_, index) => (
									<TableRowSkeleton
										key={index}
										colSpan={11}
										height={'42.50px'}
									/>
							  ))
							: rows}
					</tbody>
				</Table>
			</ScrollArea>
		</Paper>
	);
}
