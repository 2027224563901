import { createStyles, Grid, Text } from '@mantine/core';
import UserCard from './UserCard';
import { InvitedUser } from '../../interfaces/user';
import { IOrganizer } from '../../interfaces/meeting';
interface Props {
	users: (InvitedUser | [string | null, string | null, string | null])[];
	gridColSpan: number;
	searchValue?: string;
	emptyText: string;
	handleRemoveUser?: (email: string) => void;
	inviteUser?: ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => void;
	isAttendees?: boolean;
	showLinkedIn?: boolean;
	hideIcons?: boolean;
	organizer?: IOrganizer;
	owningUserID?: string;
}

const useStyles = createStyles(() => ({
	container: {
		maxHeight: '40%',
	},
}));

const UserList = ({
	users,
	gridColSpan,
	emptyText,
	handleRemoveUser,
	inviteUser,
	searchValue = '',
	isAttendees = false,
	showLinkedIn = true,
	hideIcons = false,
	organizer = null,
	owningUserID = null,
}: Props) => {
	const { classes } = useStyles();

	return (
		<>
			{users.length ? (
				<Grid className={classes.container}>
					{users.map((user, index) => {
						const uniqueKey = `${index}-${Math.random()}`;
						if (Array.isArray(user)) {
							const [email = '', firstName = '', lastName = ''] = user;
							return (
								<UserCard
									key={uniqueKey}
									gridColSpan={gridColSpan}
									email={email}
									firstName={firstName}
									lastName={lastName}
									searchValue={searchValue}
									handleRemoveUser={handleRemoveUser}
									isAttendees={isAttendees}
									showLinkedIn={showLinkedIn}
									organizer={organizer}
									owningUserID={owningUserID}
									inviteUser={inviteUser}
									hideIcons={hideIcons}
								/>
							);
						} else if (user && typeof user === 'object') {
							const {
								email = '',
								firstName = '',
								lastName = '',
								id = '',
							} = user;
							return (
								<UserCard
									key={uniqueKey}
									gridColSpan={gridColSpan}
									email={email}
									firstName={firstName}
									lastName={lastName}
									userID={id}
									searchValue={searchValue}
									handleRemoveUser={handleRemoveUser}
									isAttendees={isAttendees}
									showLinkedIn={showLinkedIn}
									organizer={organizer}
									owningUserID={owningUserID}
									inviteUser={inviteUser}
									hideIcons={hideIcons}
								/>
							);
						}
						return null;
					})}
				</Grid>
			) : (
				<Text size={14} align={'left'}>
					{emptyText}
				</Text>
			)}
		</>
	);
};

export default UserList;
