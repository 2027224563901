import { logger } from '../helpers/logger';
import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	timeout: 60000,
});

const axiosInstanceWithLongTimeout = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	// timeout: 1800000, // 30 minutes
	timeout: 90000, // 90 secs
});

export const getRecallAIBotDetails = (recallBotID: string) => {
	return axiosInstance
		.get(`/v1/recall-ai/bot/${recallBotID}`, {})
		.then((res) => {
			logger('info', 'Response received from getMeetingNotifications', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			return err;
		});
};

export const resyncCalendars = (userID: string, organizationID: string) => {
	return axiosInstance.post(`/v1/calendar/resync`, {
		userID,
		organizationID,
	});
};

export const massCalendarResync = (secretKey: string) => {
	return axiosInstanceWithLongTimeout.post(`/v1/calendars/resync`, {
		secretKey,
	});
};
