import React, { useEffect, useState } from 'react';
import {
	ActionIcon,
	Button,
	CopyButton,
	FocusTrap,
	Group,
	rem,
	Stack,
	Switch,
	TextInput,
} from '@mantine/core';
import { ShareableLink } from '../../../../helpers/links';
import { updateMeetingShareableLink } from '../../../../api/api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	meetingShareableLinks,
	showLinkRow,
} from '../../../../Atoms/meetingAtom';
import { compareByTimestamp } from '../../../../helpers/timestamp';
import { DisabledTextLink } from './DisabledTextLink';
import { DeleteIcon } from './DeleteIcon';
import { currentUser } from '../../../../Atoms/userAtoms';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';

interface Props {
	link: ShareableLink;
	saveButton?: boolean;
	deleteShareableLink: (link: ShareableLink) => void;
	isMeetingHostOrPM?: boolean;
	mobile?: boolean;
}
export const ShareLinkRow = ({
	link,
	mobile,
	saveButton = false,
	deleteShareableLink,
	isMeetingHostOrPM,
}: Props) => {
	const { id, url, title, organizationID, meetingID, status, userID } = link;
	const setLinkList = useSetRecoilState(meetingShareableLinks);
	const [value, setValue] = useState(title);
	const [checked, setChecked] = useState(status === 'active');
	const setShowLinkRow = useSetRecoilState(showLinkRow);
	const user = useRecoilValue(currentUser);
	const ownsLink = user.id === userID;

	useEffect(() => {
		setValue(title);
	}, [title]);

	useEffect(() => {
		setChecked(status === 'active');
	}, [link]);

	const updateShareableLink = async (
		payload,
		openNotification,
		closeLinkRow
	) => {
		// update link title whenever you click outside the text input
		try {
			// update shareable link
			const updatedLink = await updateMeetingShareableLink(
				payload,
				id,
				meetingID,
				organizationID
			);
			// update links list in state
			setLinkList((list) =>
				list
					.map((link) =>
						link.id === updatedLink?.data?.data?.id
							? updatedLink?.data?.data
							: link
					)
					.slice()
					.sort((a, b) => compareByTimestamp(a, b, 'createdAt', 'asc'))
			);
			// update state value
			setValue(updatedLink?.data?.data?.title);
			if (closeLinkRow) setShowLinkRow(false);
			openNotification &&
				showSuccessNotification({
					message: 'Link was successfully updated. Great work!',
				});
		} catch (error) {
			console.log('error', error);
			showFailureNotification({
				message: 'Failed to update link. Please try again.',
			});
		}
	};

	return (
		<>
			{mobile ? (
				<FocusTrap active={true}>
					<Stack key={id} spacing={'lg'}>
						<TextInput
							placeholder={'Title'}
							value={value}
							onChange={(event) => setValue(event.currentTarget.value)}
							onBlur={() => updateShareableLink({ title: value }, false, false)}
							data-autofocus
						/>
						<Group noWrap>
							<DisabledTextLink url={url} />
							<CopyButton value={url} timeout={2000}>
								{({ copied, copy }) => (
									<ActionIcon
										color={copied ? 'teal' : 'gray'}
										variant='subtle'
										onClick={copy}
									>
										{copied ? (
											<IconCheck style={{ width: rem(16) }} />
										) : (
											<IconCopy style={{ width: rem(16) }} />
										)}
									</ActionIcon>
								)}
							</CopyButton>
							{/*User must be owner of link or a meeting host to change status or delete*/}
							{ownsLink || isMeetingHostOrPM ? (
								<Group spacing={'xs'} noWrap>
									{saveButton ? (
										<Button
											onClick={() =>
												updateShareableLink({ title: value }, false, true)
											}
										>
											Save
										</Button>
									) : (
										<Switch
											styles={{ trackLabel: { fontSize: 10 } }}
											size={'md'}
											onLabel={'ON'}
											offLabel={'OFF'}
											checked={checked}
											onChange={(event) => {
												setChecked(event.currentTarget.checked);
												updateShareableLink(
													{
														status: event.currentTarget.checked
															? 'active'
															: 'inactive',
													},
													false,
													false
												);
											}}
										/>
									)}
								</Group>
							) : null}
						</Group>
					</Stack>
				</FocusTrap>
			) : (
				<FocusTrap active={true}>
					<Group key={id} position={'apart'} noWrap align={'center'}>
						<TextInput
							style={{ flex: 2 }}
							placeholder={'Title'}
							value={value}
							onChange={(event) => setValue(event.currentTarget.value)}
							onBlur={() => updateShareableLink({ title: value }, false, false)}
							data-autofocus
						/>
						<DisabledTextLink url={url} />
						{/*User must be owner of link or a meeting host to change status or delete*/}
						{ownsLink || isMeetingHostOrPM ? (
							<Group spacing={'xs'} noWrap w={88} position={'center'}>
								{saveButton ? (
									<Button
										onClick={() =>
											updateShareableLink({ title: value }, true, true)
										}
									>
										Save
									</Button>
								) : (
									<Switch
										styles={{ trackLabel: { fontSize: 10 } }}
										size={'md'}
										onLabel={'ON'}
										offLabel={'OFF'}
										checked={checked}
										onChange={(event) => {
											setChecked(event.currentTarget.checked);
											updateShareableLink(
												{
													status: event.currentTarget.checked
														? 'active'
														: 'inactive',
												},
												false,
												false
											);
										}}
									/>
								)}
								{!saveButton ? (
									<DeleteIcon
										link={link}
										deleteShareableLink={deleteShareableLink}
									/>
								) : null}
							</Group>
						) : null}
					</Group>
				</FocusTrap>
			)}
		</>
	);
};
