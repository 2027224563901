import { UserList } from './user';
import { IAttachment, User } from '../Atoms/userAtoms';
import { IAction } from './action';
import { RecallBot } from './recallai';

export const defaultMeeting = {
	id: '',
	createdAt: '',
	publishedAt: null,
	auditTime: null,
	reviewTime: null,
	friendlyID: '',
	agenda: '',
	isExpired: false,
	recapOnly: false,
	invitedUsers: [],
	name: '',
	objective: '',
	organizationID: '',
	owningUserID: '',
	status: '',
	summary: '',
	updatedAt: '',
	videoMetaData: {},
	viewingDeadline: '',
	platform: 'uploaded',
	thumbnailTime: null,
	collectionIDs: [],
};
export interface IMeeting {
	agenda?: string;
	auditTime: string | null;
	actionsCount?: number;
	attachmentsCount?: number;
	author?: User;
	organizer?: IOrganizer;
	createdAt: string;
	publishedAt: string | null;
	isExpired: boolean;
	friendlyID?: string;
	highlightCount?: number;
	id?: string;
	invitedUsers?: (UserList | string)[];
	meetingDate?: string;
	name?: string;
	objective?: string;
	organizationID?: string;
	platform: string;
	reviewTime: string | null;
	recapOnly: boolean;
	owningUserID?: string;
	priority?: number;
	status?: string;
	startAt?: string;
	summary?: string;
	longSummary?: string;
	updatedAt?: string;
	videoMetadata?: VideoMetadata;
	viewingDeadline?: string | null;
	watched?: boolean;
	views?: number;
	actions?: IAction[];
	channelID?: string | null;
	teamID?: string | null;
	joinAt?: string | null;
	botMetadata?: RecallBot | null;
	thumbnailTime: number | null;
	collectionIDs?: string[];
	questionsCount?: number;
	attachments?: IAttachment[];
	sharingToken?: SharingToken;
}

export interface VideoMetadata {
	id: string;
	assetID: string;
	createdAt?: string;
	expiresAt?: string;
	playbackID?: string | null;
	playbackURL?: string;
	status?: string;
	targetID?: string;
	updatedAt?: string;
	uploadID?: string;
	uploadURL?: string;
	userID: string;
	duration?: number;
	source?: string;
}

export interface SharingToken {
	id: string;
	active: boolean;
	targetID: string;
	targetType: string;
	token: string;
}

export interface MeetingDetails {
	name: string;
	objective: string;
	agenda: string;
	organizationID: string;
	status: string;
	viewingDeadline?: string | null;
	publishedAt?: number;
}

export interface HostDataObj {
	firstName: string;
	lastName: string;
	userAvatarURL: string;
	id: string;
	email: string;
	avatarSizes: number[];
	dob: string | null;
	phone: string | null;
	roles: string[];
	tags: [];
	createdAt: string;
	updatedAt: string;
}

export interface IOrganizer {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	organizationID: string;
	userID: string;
}
