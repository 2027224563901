import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Container,
	createStyles,
	Paper,
	ScrollArea,
	Tabs,
	Title,
} from '@mantine/core';
import Integrations from '../integrations';
import Teams from '../teams';
import OrganizationMembersTable from '../../components/organization_members_table';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../Atoms/userAtoms';
import MyProfile from './tabs/MyProfile';
import { convertRemToPx } from '../../_utils/handy-functions';
import Security from './tabs/Security';
import { useFlags } from 'flagsmith/react';
import { isSuperadmin } from '../../helpers/auth';
import { getEnvForGleap } from '../../_utils/trackers';

const useStyles = createStyles((theme) => ({
	wrapper: {
		paddingTop: convertRemToPx(theme.spacing.xl) * 2,
		paddingBottom: convertRemToPx(theme.spacing.xl) * 2,
		minHeight: 650,
		height: 'calc(100vh - 86px)',
		boxSizing: 'border-box',
		maxWidth: '90%',
		borderTop: '#FFFFFF',
		overflow: 'hidden',
	},

	title: {
		marginBottom: convertRemToPx(theme.spacing.xl) * 2,
	},

	item: {
		borderRadius: theme.radius.md,
		marginBottom: theme.spacing.lg,

		border: `1px solid ${theme.colors['primary'][0]}`,
	},
	tab: {
		height: 'rem(38)',
		backgroundColor: 'transparent',
		position: 'relative',
		bottom: '-1px',
		borderBottom: 'transparent',

		'&:hover': {
			backgroundColor: '#E8ECF0',
			borderColor: '#DEE2E6',
			borderBottomColor: 'transparent',
		},
		' &[data-active]': {
			backgroundColor: '#ffffff',
			borderColor: '#DEE2E6',
			borderBottomColor: 'transparent',
		},
	},
	tabs: {
		border: 'none',
	},
	paper: {
		overflow: 'hidden',
	},
}));

export default function Settings() {
	const user = useRecoilValue(currentUser);
	const flags = useFlags(['settings_security']);
	const securityEnabled = flags.settings_security.enabled;

	const navigate = useNavigate();
	const { tabValue = 'profile' } = useParams();
	const { classes } = useStyles();
	const isSuperAdmin = isSuperadmin(user.roles);
	const { currentOrganizationID } = user;

	return (
		<Container size='lg' className={classes.wrapper}>
			<Tabs
				h={'100%'}
				w={'100%'}
				radius={'md'}
				value={tabValue}
				onTabChange={(value) => navigate(`/settings/${value}`)}
				variant={'outline'}
			>
				<Tabs.List
					grow
					className={classes.tabs}
					id={`VIS_ctf9nfdtmsxfgg2vmcjg_${getEnvForGleap()}`}
				>
					<Tabs.Tab className={classes.tab} value='profile'>
						<Title order={6}>Profile</Title>
					</Tabs.Tab>
					{securityEnabled && isSuperAdmin ? (
						<Tabs.Tab className={classes.tab} value='security'>
							<Title order={6}>Security</Title>
						</Tabs.Tab>
					) : null}
					<Tabs.Tab className={classes.tab} value='teams'>
						<Title order={6}>Teams</Title>
					</Tabs.Tab>
					<Tabs.Tab className={classes.tab} value='members'>
						<Title order={6}>Team Members</Title>
					</Tabs.Tab>
					{/*<Tabs.Tab value="security">Security</Tabs.Tab>*/}
					{/*<Tabs.Tab value="notifications">Notifications</Tabs.Tab>*/}
					{/*<Tabs.Tab value="billing">Billing</Tabs.Tab>*/}
					<Tabs.Tab className={classes.tab} value='integrations'>
						<Title order={6}>Integrations</Title>
					</Tabs.Tab>
				</Tabs.List>
				<Paper
					h={'100%'}
					shadow='xl'
					p='sm'
					withBorder
					className={classes.paper}
				>
					<ScrollArea h={'100%'}>
						<Tabs.Panel value='profile' pl='xs'>
							<MyProfile />
						</Tabs.Panel>

						{securityEnabled && isSuperAdmin ? (
							<Tabs.Panel
								style={{ overflowX: 'auto' }}
								value='security'
								pl='xs'
								h={'100%'}
							>
								<Security />
							</Tabs.Panel>
						) : null}

						<Tabs.Panel value='teams' pl='xs'>
							<Teams />
						</Tabs.Panel>
						<Tabs.Panel value='members' pl='xs'>
							<OrganizationMembersTable
								tabValue={tabValue}
								organizationID={currentOrganizationID}
								showSkipAudit={false}
							/>
						</Tabs.Panel>
						<Tabs.Panel value='notifications' pl='xs'>
							notifications content
						</Tabs.Panel>
						<Tabs.Panel value='billing' pl='xs'>
							Billing content
						</Tabs.Panel>
						<Tabs.Panel value='integrations' pl='xs'>
							<Integrations />
						</Tabs.Panel>
					</ScrollArea>
				</Paper>
			</Tabs>
		</Container>
	);
}
