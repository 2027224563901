import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	Button,
	Dialog,
	Group,
	Stack,
	Tabs,
	TextInput,
	Textarea,
	createStyles,
} from '@mantine/core';
import { IMeeting } from '../../../../interfaces/meeting';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	currentMeeting,
	currentMeetingActions,
	currentMeetingChapters,
	currentMeetingHighlights,
} from '../../../../Atoms/meetingAtom';
import MomentsList from './components/MomentsList';
import { IHighlight } from '../../../../interfaces/highlight';
import { IAction } from '../../../../interfaces/action';
import { Chapter } from '../../../../helpers/meetings';
import { CreateHighlightDialog } from '../../../../components/aside/Dialogs/CreateHighlightDialog';
import {
	actionChipSet,
	momentChipSet,
	momentTypeChipSet,
} from '../../../../helpers/highlights';
import { currentUser, editingHighlightAtom } from '../../../../Atoms/userAtoms';
import { isMeetingOwnerOrProjectManager } from '../../../../helpers/auth';
import { useForm } from '@mantine/form';
import { MILLISECONDS_PER_SECOND } from '../../../../components/constants';
import {
	convertTimeToSeconds,
	formatSecondsToMinutesAndSeconds,
} from '../../../../_utils/time';
import { useDebouncedValue, useDisclosure, useFocusTrap } from '@mantine/hooks';
import TimestampInput from '../../../../components/TimestampInput';
import { logger } from '../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { sortChaptersByTime } from '../../../../helpers/chapters';
import { updateMeetingChapter } from '../../../../api/api';
import _ from 'lodash';
import useMediaQueries from '../../../../customHooks/useMediaQueries';

const tabPaths = [
	{ label: 'Topics', param: 'topic' },
	{ label: 'Highlights', param: 'highlight' },
	{ label: 'Actions', param: 'action' },
	{ label: 'Questions', param: 'question' },
];

const TABS_LIST_HEIGHT_PX = 51.32;

const useStyles = createStyles((theme) => ({
	tab: {
		color: theme.colors?.['secondary-text'][0],
	},
	tabSelected: {
		color: theme.fn.primaryColor(),
	},
}));

interface Props {
	cardRef: MutableRefObject<HTMLDivElement>;
	searchValue: string;
}

function MomentsTabs({ cardRef, searchValue }: Props) {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const tabsListRef = useRef<HTMLDivElement>(null);
	const { meetingID } = useParams();
	const { widthOrHeightLessThanLG } = useMediaQueries();
	const meeting = useRecoilValue(currentMeeting);
	const { organizer, owningUserID } = meeting;
	const user = useRecoilValue(currentUser);
	const isMeetingHostOrPM = isMeetingOwnerOrProjectManager(
		organizer,
		owningUserID,
		user.id,
		user.roles
	);
	const [searchParams, setSearchParams] = useSearchParams();
	const tab = searchParams.get('tab');
	const [activeTab, setActiveTab] = useState<string>(tab || 'topic');
	const highlights = useRecoilValue(currentMeetingHighlights);
	const [chapters, setChapters] = useRecoilState(currentMeetingChapters);
	const editingHighlight = useRecoilValue(editingHighlightAtom);
	const highlightsOnly = highlights.filter((hl) => hl.type === 'highlight');
	const questionsOnly = highlights.filter((hl) => hl.type === 'question');
	const actions = highlights.filter((hl) => hl.type === 'action');
	const [items, setItems] = useState<(IHighlight | IAction | Chapter)[]>([]);
	const isActionTab = activeTab === 'action';

	// chapters editing
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [editingChapter, setEditingChapter] = useState<Chapter | null>(null);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const timeStartInSeconds = editingChapter?.time / MILLISECONDS_PER_SECOND;
	const [editingChapterTimestamp, setEditingChapterTimestamp] = useState(
		formatSecondsToMinutesAndSeconds(timeStartInSeconds)
	);
	const focusTrapRef = useFocusTrap();
	const [timestampExceedsVideoDuration, setTimestampExceedsVideoDuration] =
		useState(false);
	const [opened, { open, close }] = useDisclosure(false);

	useEffect(() => {
		setItems(getMomentType(activeTab));
	}, [activeTab, highlights, chapters]);

	useEffect(() => {
		if (activeTab !== 'topic') close();
	}, [activeTab]);

	const form = useForm({
		initialValues: {
			title: '',
			summary: '',
		},
		validate: {
			title: (value: string) => (value.length === 0 ? 'Field required.' : null),
			summary: (value: string) =>
				value.length === 0 ? 'Field required.' : null,
		},
	});

	const getMomentType = (type: string) => {
		let list;
		if (type === 'topic') list = chapters;
		if (type === 'highlight') list = highlightsOnly;
		if (type === 'action') list = actions;
		if (type === 'question') list = questionsOnly;

		return applySearchFilter(list, searchValue);
	};

	const [debounced] = useDebouncedValue(searchValue, 300);
	const [initialLoad, setInitialLoad] = useState(true);

	const applySearchFilter = (list, value) => {
		return list.filter((chapter) => {
			return (
				(chapter.content || '').toLowerCase().includes(value.toLowerCase()) ||
				(chapter.headline || '').toLowerCase().includes(value.toLowerCase()) ||
				(chapter.summary || '').toLowerCase().includes(value.toLowerCase())
			);
		});
	};

	useEffect(() => {
		if (initialLoad) setInitialLoad(false);

		if (!initialLoad) {
			const items = getMomentType(activeTab);
			setItems(applySearchFilter(items, debounced));
			// apply filter and setItems
		}
	}, [debounced]);

	const editChapter = async (values) => {
		const { title, summary } = values;
		try {
			setSubmitting(true);
			// update existing chapter.
			const time =
				convertTimeToSeconds(editingChapterTimestamp) * MILLISECONDS_PER_SECOND;
			const updatedChapter = await updateMeetingChapter(
				{
					headline: title,
					gist: title,
					content: title,
					start: time,
					end: time,
					summary,
				},
				meeting.id,
				editingChapter.id,
				meeting.organizationID
			);
			const {
				id,
				gist,
				start,
				summary: newSummary,
			} = _.get(updatedChapter, 'data.data', {});
			if (!id) {
				throw new Error(`Unexpected response format`);
			}

			setChapters(
				sortChaptersByTime(
					(items as Chapter[]).map((chapter) =>
						chapter.id === id
							? { id, time: start, content: gist, summary: newSummary }
							: chapter
					)
				)
			);

			showSuccessNotification({
				message: 'Meeting topic was successfully updated.',
			});
			form.reset();
			close();
		} catch (error) {
			logger('error', 'adding or updating chapter', error);
			showFailureNotification({
				message: 'Failed to add or update meeting topic. Please try again.',
			});
		} finally {
			setSubmitting(false);
		}
	};

	const handleEditChapter = (chapter: Chapter) => {
		// open edit chapter modal
		setEditingChapter(chapter);
		// set form fields
		form.setValues({
			title: chapter.content,
			summary: chapter.summary,
		});
		setEditingChapterTimestamp(
			formatSecondsToMinutesAndSeconds(chapter.time / MILLISECONDS_PER_SECOND)
		);
		open();
	};

	return (
		<>
			<Tabs
				value={activeTab}
				onTabChange={(value) => {
					setActiveTab(value);
					setSearchParams({
						...Object.fromEntries(searchParams.entries()),
						tab: value,
					});
					// navigate(`/meetings/${meetingID}/${value}`);
				}}
				styles={(theme) => ({
					tab: {
						fontSize: 14,
						padding: widthOrHeightLessThanLG ? 8 : 12,
						borderBottomWidth: `3px`,
						fontWeight: 700,
						color: '#8C919B',
					},
					root: {
						display: 'flex',
						flexDirection: 'column',
						flex: '2 1 0%',
						height: '50%',
					},
					panel: {
						flex: 2,
						height: '50%',
						display: 'flex',
						flexDirection: 'column',
					},
					tabsList: {
						flexWrap: 'nowrap',
					},
				})}
			>
				<Tabs.List ref={tabsListRef}>
					{tabPaths.map((tabPath) => (
						<Tabs.Tab value={tabPath.param} key={tabPath.param}>
							<span
								style={{
									color:
										activeTab === tabPath.param
											? theme.fn.primaryColor()
											: theme.colors?.['secondary-text'][0],
								}}
							>
								{`${tabPath.label}${
									activeTab !== tabPath.param
										? ` (${getMomentType(tabPath.param).length})`
										: ''
								}`}
							</span>
						</Tabs.Tab>
					))}
				</Tabs.List>

				{tabPaths.map((tabPath) => (
					<Tabs.Panel value={tabPath.param} key={tabPath.param}>
						<Stack
							mah={'100%'}
							h={'100%'}
							justify='flex-start'
							style={{
								flex: 2,
							}}
						>
							<MomentsList
								items={items}
								emptyText={`${activeTab}s`}
								handleEditChapter={handleEditChapter}
								tab={tab}
							/>
						</Stack>
					</Tabs.Panel>
				))}
			</Tabs>
			<CreateHighlightDialog
				meeting={meeting}
				cardRef={cardRef}
				initialTypeValue={isActionTab ? 'action' : ''}
				allowedTypes={momentTypeChipSet}
				title={
					editingHighlight
						? `Edit ${isActionTab ? 'Action' : 'Moment'}`
						: `New ${isActionTab ? 'Action' : 'Moment'}`
				}
				buttonText={
					editingHighlight
						? `Save ${isActionTab ? 'Action' : 'Moment'}`
						: `+ Create ${isActionTab ? 'Action' : 'Moment'}`
				}
			/>
			{/* Edit Chapter dialog  */}
			{isMeetingHostOrPM ? (
				<Dialog
					opened={opened}
					onClose={() => {
						setSubmitting(false);
						form.reset();
						close();
					}}
					withCloseButton
					size='lg'
					radius='md'
					transition={'slide-up'}
					position={{ bottom: 8, right: 16 }}
					p={16}
					w={cardRef?.current?.offsetWidth}
					sx={{ zIndex: 0 }}
				>
					<form onSubmit={form.onSubmit((values) => editChapter(values))}>
						<Stack ref={focusTrapRef}>
							<TimestampInput
								value={editingChapterTimestamp}
								setValue={setEditingChapterTimestamp}
								duration={meeting?.videoMetadata?.duration}
								error={timestampExceedsVideoDuration}
								setError={setTimestampExceedsVideoDuration}
							/>
							<TextInput
								placeholder={'Topic Title'}
								{...form.getInputProps('title')}
								data-autofocus
							/>
							<Textarea
								placeholder={'Topic Summary'}
								{...form.getInputProps('summary')}
								minRows={6}
							/>
							<Group position={'right'}>
								<Button
									type={'submit'}
									disabled={isSubmitting || timestampExceedsVideoDuration}
								>
									{'Save'}
								</Button>
							</Group>
						</Stack>
					</form>
				</Dialog>
			) : null}
		</>
	);
}

export default MomentsTabs;
