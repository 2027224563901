import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Menu,
	Group,
	createStyles,
	Text,
	CopyButton,
} from '@mantine/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import {
	faFolderGear,
	faPencil,
	faRectangleHistoryCirclePlus,
	faUserLock,
	faTrash,
	faBoxArchive,
} from '@fortawesome/pro-regular-svg-icons';
import {
	addMeetingToCollectionModalOpenedAtom,
	archiveCollectionAtom,
	meetingBeingAddedToCollection,
} from '../../Atoms/collections';
import { IMeeting } from '../../interfaces/meeting';
import { useNavigate } from 'react-router';
import { modals } from '@mantine/modals';
import { destroyMeeting } from '../../api/api';
import { logger } from '../../helpers/logger';
import { useCollections } from '../../customHooks/useCollections';
import { useFlags } from 'flagsmith/react';
import useMediaQueries from '../../customHooks/useMediaQueries';
import { flagsmithFeatureFlags } from '../../components/constants';
import EditMeetingNameModal from '../Modals/EditMeetingNameModal';
import { useState } from 'react';
import { organizationSettings } from '../../Atoms/settings';

const useStyles = createStyles((theme) => ({
	button: {
		borderRadius: theme.radius.md,
	},
}));

interface Props {
	openSharingModal: () => void;
	openRequestsModal: () => void;
	openMyLinksModal: () => void;
	openManageAccessModal: () => void;
	isMeetingHostOrPM: boolean;
	meeting: IMeeting;
}
export function ShareButton({
	openSharingModal,
	openRequestsModal,
	openMyLinksModal,
	openManageAccessModal,
	isMeetingHostOrPM,
	meeting,
}: Props) {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const { widthOrHeightLessThanLG } = useMediaQueries();
	const flags = useFlags([
		flagsmithFeatureFlags.queueControls,
		flagsmithFeatureFlags.meetingCollections,
	]);
	const collectionsEnabled = flags?.meeting_collections?.enabled;
	const { sharing: sharingEnabled } = useRecoilValue(organizationSettings);
	const { id, organizationID } = meeting;
	const archiveCollection = useRecoilValue(archiveCollectionAtom);
	const setAddMeetingToCollectionModalOpened = useSetRecoilState(
		addMeetingToCollectionModalOpenedAtom
	);
	const { addMeetingsToCollection, removeMeetingFromCollection } =
		useCollections(false);
	const setMeetingToAdd = useSetRecoilState(meetingBeingAddedToCollection);
	const isArchived = archiveCollection?.content.some((c) => c.meetingID === id);

	const [editNameModalOpened, setEditNameModalOpened] = useState(false);

	const openConfirmDeleteMeetingModal = () =>
		modals.openConfirmModal({
			title: 'Deleting this meeting',
			children: (
				<Text size='sm'>Are you sure you want to delete this meeting?</Text>
			),
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			confirmProps: { color: 'red' },
			onConfirm: async () => {
				try {
					await destroyMeeting(id, organizationID);
					showSuccessNotification({
						message: `Meeting was successfully deleted.`,
					});
					navigate('/meetings');
					// re-fetch the meetings list so the page is full and the pagination is correct.
				} catch (err) {
					logger('error', 'Error deleting meeting', err);
					showFailureNotification({
						message: `Apologies, the meeting was not successfully deleted. Please try the operation again.`,
					});
				}
			},
		});

	const handleArchiveMeeting = async () => {
		try {
			await addMeetingsToCollection(archiveCollection.id, [id]);
			navigate('/meetings');
			showSuccessNotification({
				message: `Meeting was successfully archived.`,
			});
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully archived. Please try the operation again.`,
			});
		}
	};

	const handleUnarchiveMeeting = async () => {
		try {
			await removeMeetingFromCollection(archiveCollection, id);
			showSuccessNotification({
				message: `Meeting was successfully unarchived.`,
			});
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully unarchived. Please try the operation again.`,
			});
		}
	};

	return (
		<>
			<EditMeetingNameModal
				opened={editNameModalOpened}
				setOpened={setEditNameModalOpened}
			/>
			<Group noWrap spacing={0} align='flex-start'>
				{sharingEnabled && isMeetingHostOrPM ? (
					<Button className={classes.button} onClick={openSharingModal}>
						Share
					</Button>
				) : (
					<CopyButton value={window.location.href}>
						{({ copied, copy }) => (
							<Button
								onClick={copy}
								variant='subtle'
								fz={widthOrHeightLessThanLG ? 12 : 14}
							>
								{copied ? 'Copied' : 'Copy link'}
							</Button>
						)}
					</CopyButton>
				)}

				<Menu
					shadow='md'
					width={275}
					zIndex={500000}
					transitionProps={{ transition: 'pop' }}
					position='bottom-end'
					withinPortal
				>
					<Menu.Target>
						<FontAwesomeIcon
							icon={faEllipsisVertical}
							size={'xl'}
							style={{
								color: theme.colors['secondary-gray'][0],
								cursor: 'pointer',
								padding: 8,
								paddingLeft: 16,
								position: 'relative',
								bottom: 2,
							}}
						/>
					</Menu.Target>

					<Menu.Dropdown>
						{collectionsEnabled && (
							<>
								<Menu.Label>Meeting Collections</Menu.Label>
								<Menu.Item
									onClick={(e) => {
										// e.stopPropagation();
										setAddMeetingToCollectionModalOpened(true);
										setMeetingToAdd(meeting);
									}}
									icon={
										<FontAwesomeIcon
											style={{
												cursor: 'pointer',
											}}
											icon={faRectangleHistoryCirclePlus}
											size={'sm'}
										/>
									}
								>
									Add to a Collection
								</Menu.Item>
								<Menu.Item
									onClick={(e) => {
										e.stopPropagation();
										navigate('/collections');
									}}
									icon={
										<FontAwesomeIcon
											style={{
												cursor: 'pointer',
											}}
											icon={faFolderGear}
											size={'sm'}
										/>
									}
								>
									Manage Collection(s)
								</Menu.Item>
								<Menu.Item
									onClick={(e) => {
										e.stopPropagation();
										isArchived
											? handleUnarchiveMeeting()
											: handleArchiveMeeting();
									}}
									icon={
										<FontAwesomeIcon
											style={{
												cursor: 'pointer',
											}}
											icon={faBoxArchive}
											size={'sm'}
										/>
									}
								>
									{isArchived ? 'Unarchive' : 'Archive'}
								</Menu.Item>

								<Menu.Divider />
							</>
						)}

						{sharingEnabled && isMeetingHostOrPM && (
							<>
								<Menu.Label>Manage</Menu.Label>
								<Menu.Item
									icon={
										<FontAwesomeIcon
											style={{
												cursor: 'pointer',
											}}
											icon={faUserLock}
											size={'sm'}
										/>
									}
									onClick={(e) => {
										e.stopPropagation();
										openManageAccessModal();
									}}
								>
									Manage Access
								</Menu.Item>
							</>
						)}

						{isMeetingHostOrPM && (
							<Menu.Item
								icon={
									<FontAwesomeIcon
										style={{
											cursor: 'pointer',
										}}
										icon={faPencil}
										size={'sm'}
									/>
								}
								onClick={(e) => {
									e.stopPropagation();
									setEditNameModalOpened(true);
								}}
							>
								Edit
							</Menu.Item>
						)}

						{isMeetingHostOrPM && (
							<Menu.Item
								icon={
									<FontAwesomeIcon
										style={{
											cursor: 'pointer',
										}}
										icon={faTrash}
										size={'sm'}
									/>
								}
								onClick={(e) => {
									e.stopPropagation();
									openConfirmDeleteMeetingModal();
								}}
							>
								Delete
							</Menu.Item>
						)}
					</Menu.Dropdown>
				</Menu>
			</Group>
		</>
	);
}
