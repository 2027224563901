import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import MeetingHostCard from '../../../../../components/MeetingHostCard';
import { Transition } from 'react-transition-group';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	attendeesList,
	currentMeeting,
	showAllAttendeesAtom,
} from '../../../../../Atoms/meetingAtom';
import {
	Divider,
	Group,
	ScrollArea,
	Stack,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralSearchBar from '../../GeneralSearchBar';
import UserList from '../../../../../components/UserList';
import { useDebouncedValue } from '@mantine/hooks';
import {
	applySearchFilter,
	deduplicateUsers,
} from '../../../../../helpers/users';

const duration = 400;

const defaultStyle = {
	transition: `height ${duration}ms ease-out`,
	height: '0%',
};

const transitionStyles = {
	entering: { height: '0%' },
	entered: { height: '100%' },
	exiting: { height: '100%%' },
	exited: { height: '0%' },
};

interface Props {
	searchValue: string;
	setSearchValue: Dispatch<SetStateAction<string>>;
	inviteUser?: ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => void;
}
export default function ExpandedAttendeesView({
	searchValue,
	setSearchValue,
	inviteUser,
}: Props) {
	const theme = useMantineTheme();
	const nodeRef = useRef(null);
	const [showAllAttendees, setShowAllAttendees] =
		useRecoilState(showAllAttendeesAtom);
	const attendees = useRecoilValue(attendeesList);
	const meeting = useRecoilValue(currentMeeting);
	const [userList, setUserList] = useState(attendees);
	const [debounced] = useDebouncedValue(searchValue, 300);
	const [initialLoad, setInitialLoad] = useState(true);

	useEffect(() => {
		if (initialLoad) setInitialLoad(false);

		if (!initialLoad) {
			const deduplicatedUsers = deduplicateUsers(attendees);
			const filteredUsers = applySearchFilter(deduplicatedUsers, debounced);
			setUserList(filteredUsers);
		}
	}, [debounced, attendees]);

	useEffect(() => {
		setUserList(deduplicateUsers(attendees));
		setSearchValue('');
	}, [attendees]);

	return (
		<Transition nodeRef={nodeRef} in={showAllAttendees} timeout={duration}>
			{(state) => (
				<Stack
					bg={'background-gray-2'}
					p={'md'}
					style={{
						...defaultStyle,
						...transitionStyles[state],
						position: 'absolute',
						bottom: showAllAttendees ? 0 : '-15px',
						left: 0,
						right: 0,
						zIndex: 100,
					}}
					sx={(theme) => ({
						borderTopLeftRadius: theme.spacing.lg,
						borderTopRightRadius: theme.spacing.lg,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					})}
				>
					<Group position='apart' noWrap>
						<Text
							color='primary-text'
							size={'xl'}
							fw={700}
						>{`Attendees (${attendees.length})`}</Text>
						<FontAwesomeIcon
							icon={faChevronUp}
							size={'xl'}
							color={theme.colors['primary-cta'][0]}
							style={{
								position: 'relative',
								top: '4px',
								cursor: 'pointer',
							}}
							onClick={(e) => {
								e.stopPropagation();
								setShowAllAttendees(false);
							}}
						/>
					</Group>
					<Stack spacing={0}>
						<Text color='primary-text' size={16} fw={500}>
							Meeting Host
						</Text>
						<MeetingHostCard meetingHost={meeting.author} />
					</Stack>
					<Divider color='#8C919B' />
					<GeneralSearchBar
						value={searchValue}
						setValue={setSearchValue}
						placeholder='Search for a user'
						width={'90%'}
						maxWidth={'90%'}
					/>
					<ScrollArea
						lh={'25px'}
						type='always'
						offsetScrollbars
						scrollbarSize={10}
						styles={{
							root: {
								flex: 2,
								marginBottom: 16,
							},
							viewport: {
								overflowX: 'hidden',
								width: '100%',
								maxWidth: '100%',
								fontSize: 12,
								flex: 2,
							},
						}}
					>
						<UserList
							users={userList}
							gridColSpan={12}
							emptyText={'No attendees found.'}
							isAttendees={true}
							hideIcons={false}
							inviteUser={inviteUser}
						/>
					</ScrollArea>
				</Stack>
			)}
		</Transition>
	);
}
