import {
	ActionIcon,
	Button,
	Group,
	List,
	Paper,
	Stack,
	Text,
	TextInput,
} from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import { useState } from 'react';
import { logger } from '../../../../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { saveOrganizationSettings } from '../../../../../../../api/settings';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faTrash } from '@fortawesome/pro-regular-svg-icons';

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
}

export default function InternalEmailDomainsCard({
	organizationSettings,
	setOrganizationSettings,
}: Props) {
	const { classes, theme } = styles();
	const { organizationID } = useParams();
	const { internal_email_domains = [] } = organizationSettings;
	const [value, setValue] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleAddEmailDomain = async (value: string) => {
		try {
			setLoading(true);
			const domains = [...(internal_email_domains || []), value].map((domain) =>
				domain.toLowerCase().trim()
			);
			// setChecked(value);
			const payload = [
				{
					key: 'internal_email_domains',
					value: domains,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: `You have successfully added a new internal email domain: ${value}.`,
			});
		} catch (error) {
			logger('error', 'error adding internal email domain', error);
			showFailureNotification({
				message:
					'An error occurred while adding a new internal email domain. Please try again.',
			});
		} finally {
			setValue('');
			setLoading(false);
		}
	};

	const handleRemoveDomain = async (value: string) => {
		try {
			setLoading(true);
			const domains = [...(internal_email_domains || [])]
				.filter((domain) => domain !== value)
				.map((domain) => domain.toLowerCase().trim());
			// setChecked(value);
			const payload = [
				{
					key: 'internal_email_domains',
					value: domains,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: `You have successfully removed internal email domain: ${value}.`,
			});
		} catch (error) {
			logger('error', 'error removing internal email domain', error);
			showFailureNotification({
				message:
					'An error occurred while removing a new internal email domain. Please try again.',
			});
		} finally {
			setLoading(false);
		}
	};

	const validateDomain = (domain: string) => {
		if (!domain.includes('.')) {
			setError('The domain must contain a period "."');
			return false;
		} else if (internal_email_domains.includes(domain)) {
			setError('This domain already exists');
			return false;
		}
		setError('');
		return true;
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Stack>
				<Text mb={4} size={'sm'}>
					Email domains added here will be considered internal in regards to the
					calendar integration settings for this organization.
				</Text>
				<List
					spacing='xs'
					size='sm'
					center
					icon={<FontAwesomeIcon color={theme.fn.primaryColor()} icon={faAt} />}
					styles={{
						itemWrapper: {
							width: '100%',
							'span:nth-child(2)': {
								flex: 2,
							},
						},
					}}
				>
					{internal_email_domains.length ? (
						internal_email_domains.map((domain) => (
							<List.Item key={domain}>
								<Group w={'100%'} position='apart'>
									<Text>{domain}</Text>
									<ActionIcon
										variant='transparent'
										color='red'
										onClick={() => handleRemoveDomain(domain)}
									>
										<FontAwesomeIcon icon={faTrash} />
									</ActionIcon>
								</Group>
							</List.Item>
						))
					) : (
						<Text>No internal email domains added yet.</Text>
					)}
				</List>
				<Group noWrap align='center' w={'100%'} position='apart'>
					<TextInput
						icon={<FontAwesomeIcon icon={faAt} />}
						value={value}
						onChange={(event) => setValue(event.currentTarget.value)}
						placeholder='enter a email domain ex. reelay.com'
						withAsterisk
						w={'50%'}
						error={error}
					/>
					<Button
						disabled={!value.length}
						type={'submit'}
						variant={'filled'}
						radius={'xl'}
						color={'primary'}
						onClick={() => {
							if (validateDomain(value)) {
								handleAddEmailDomain(value);
							}
						}}
						loading={loading}
					>
						Add
					</Button>
				</Group>
			</Stack>
		</Paper>
	);
}
