import React, { useEffect, useState } from 'react';
import {
	Table,
	Paper,
	Button,
	Popover,
	Text,
	ActionIcon,
	Group,
	Stack,
	createStyles,
	ScrollArea,
} from '@mantine/core';
import { useRecoilState } from 'recoil';
import CreateOrganizationModal from '../../../components/create_organization_modal';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPencil,
	faTrash,
	faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import {
	Organization,
	allSupportUsers,
	organizations as organizationsAtom,
} from '../../../Atoms/organizationAtom';
import { logger } from '../../../helpers/logger';
import { getAllOrganizations, getSupportUsers } from '../../../api/api';
import AvatarGroup from '../../../components/AvatarGroup';
import { showFailureNotification } from '../../../helpers/notifications';
import { useDebouncedValue } from '@mantine/hooks';

const useStyles = createStyles(() => ({
	outerContainer: {
		height: '100%',
	},
	topContainer: {},
	bottomContainer: {
		flex: 2,
		overflowY: 'auto',
	},
}));
export default function OrganizationManagement() {
	const { classes } = useStyles();
	const [organizations, setOrganizations] = useRecoilState(organizationsAtom);
	const [supportUsers, setSupportUsers] = useRecoilState(allSupportUsers);
	const [searchValue, setSearchValue] = useState<string>('');
	const [debounced] = useDebouncedValue(searchValue, 200);
	const [filteredOrganizations, setFilteredOrganizations] =
		useState<Organization[]>(organizations);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		getOrganizationsOnLoad();
	}, []);

	const getOrganizationsOnLoad = async () => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const organizations = await getAllOrganizations();
		logger('info', 'Retrieved organizations', organizations);
		setOrganizations(organizations.data.data);
		setFilteredOrganizations(organizations.data.data);
		const res = await getSupportUsers();
		setSupportUsers(res);
	};

	const deleteOrganization = async (organization: Organization) => {
		showFailureNotification({
			message: 'Sorry, deleting an organization is not yet supported.',
		});
	};

	useEffect(() => {
		// Filter data based on the debounced search value
		const lowercaseValue = debounced.toLowerCase();
		const filteredOrganizationsArray = organizations.filter((organization) => {
			return organization.name.toLowerCase().includes(lowercaseValue);
		});
		setFilteredOrganizations(filteredOrganizationsArray);
	}, [debounced, organizations]);

	const ths = (
		<tr>
			<th>Name</th>
			<th>Assigned to</th>
			<th>Created</th>
			<th>Updated</th>
			<th></th>
		</tr>
	);

	const rows = filteredOrganizations.map((organization: Organization) => (
		<tr key={organization.id}>
			<td>{organization.name}</td>
			<td>
				{organization?.supportLinks?.length ? (
					<AvatarGroup
						users={organization.supportLinks.map((link) => link.user)}
					/>
				) : (
					<Group spacing={'xs'} noWrap>
						<FontAwesomeIcon icon={faTriangleExclamation} color='red' />
						<Text>No support user assigned!</Text>
					</Group>
				)}
			</td>
			<td>
				{DateTime.fromISO(organization.createdAt).toLocaleString(
					DateTime.DATE_MED
				)}
			</td>
			<td>
				{DateTime.fromISO(organization.updatedAt).toLocaleString(
					DateTime.DATE_MED
				)}
			</td>
			<td>
				<Group position={'right'} spacing={0}>
					<ActionIcon
						m={10}
						variant='transparent'
						component={Link}
						to={`edit/${organization.id}`}
					>
						<FontAwesomeIcon icon={faPencil} />
					</ActionIcon>
					<Popover withArrow position={'left-start'} shadow='md' width={200}>
						<Popover.Target>
							<ActionIcon m={10} variant='transparent'>
								<FontAwesomeIcon icon={faTrash} />
							</ActionIcon>
						</Popover.Target>
						<Popover.Dropdown>
							<Text size='sm'>
								Are you sure you want to permanently delete this organization?
							</Text>
							<Group position={'center'}>
								<Button
									color='red'
									uppercase
									mt={'md'}
									radius={'md'}
									onClick={() => deleteOrganization(organization)}
								>
									Delete
								</Button>
							</Group>
						</Popover.Dropdown>
					</Popover>
				</Group>
			</td>
		</tr>
	));

	return (
		<Stack
			spacing={0}
			justify={'flex-start'}
			className={classes.outerContainer}
		>
			<Group position={'right'} className={classes.topContainer}>
				<CreateOrganizationModal
					searchValue={searchValue}
					setSearchValue={setSearchValue}
				/>
			</Group>
			<Paper
				className={classes.bottomContainer}
				radius='md'
				shadow='md'
				withBorder
			>
				<ScrollArea h={'100%'} offsetScrollbars>
					<Table striped highlightOnHover>
						<thead>{ths}</thead>
						<tbody>{rows}</tbody>
					</Table>
				</ScrollArea>
			</Paper>
		</Stack>
	);
}
