import React, { useLayoutEffect, useState } from 'react';
import {
	createStyles,
	Group,
	Stack,
	Text,
	Paper,
	useMantineTheme,
	Button,
	Transition,
	Badge,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { IHighlight } from '../../../interfaces/highlight';
import {
	faCommentDots,
	faPaperPlane,
	faTrash,
	faPencil,
	faLock,
} from '@fortawesome/pro-solid-svg-icons';
import { MILLISECONDS_PER_SECOND } from '../../constants';
import {
	highlightTypeLabels,
	momentTypeChipSet,
	visibilityLabels,
} from '../../../helpers/highlights';
import {
	assignMeetingActions,
	destroyMeetingHighlights,
} from '../../../api/api';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeetingActions,
	currentMeetingHighlights as HighlightAtom,
	mobileTimestamp,
	playButtonClicked as playButtonClickedAtom,
	timestamp,
} from '../../../Atoms/meetingAtom';
import { useFlags } from 'flagsmith/react';
import { useSearchParams } from 'react-router-dom';
import { IAction } from '../../../interfaces/action';
import { sortActions } from '../../../helpers/actions';
import { currentUser } from '../../../Atoms/userAtoms';
import { IMeeting } from '../../../interfaces/meeting';
import { logger } from '../../../helpers/logger';
import reactStringReplace from 'react-string-replace';
import PlayButtonWithTimestamp from './components/PlayButtonWithTimestamp';
import {
	isMeetingOwnerOrProjectManager,
	isSupportUser,
} from '../../../helpers/auth';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';

export interface HighlightCardProps {
	highlight: IHighlight | IAction;
	organizationID: string;
	isAdmin?: boolean;
	meetingInReview?: boolean;
	meeting?: IMeeting;
	index?: number;
	handleEditHighlight?: (
		highlight: IHighlight | IAction,
		index: number
	) => void;
	isAuditPage?: boolean;
	searchValue?: string;
}

const useStyles = createStyles((theme) => ({
	paper: {
		border: `1px solid ${theme.colors['secondary-text'][0]}`,
		backgroundColor: 'white',
		color: theme.colors?.['secondary-text'],
		borderRadius: '16px',
		height: 'auto',
		maxWidth: '600px',
		// padding: '16px',
		// minHeight:          '150px',
		// marginBottom:    '16px',
	},
	bulletPoint: {
		fontSize: '24px',
		lineHeight: 1,
		paddingBottom: 2.5,
	},
	cardText: {
		fontSize: 12,
		color: theme.colors?.['secondary-text'],
	},
	playButton: {
		cursor: 'pointer',
	},
	contentText: {
		fontWeight: 400,
		fontSize: 12,
		lineHeight: '14.52px',
		color: theme.colors?.['primary-text'],
		border: 'none',
		padding: 0,
	},
	completeButton: {
		backgroundColor: theme.primaryColor[1],
		color: theme.colors['primary-text'][0],
	},
}));

export const HighlightCard = ({
	highlight,
	organizationID,
	isAdmin = false,
	index,
	meeting,
	handleEditHighlight,
	isAuditPage = false,
	searchValue = '',
}: HighlightCardProps) => {
	const { classes } = useStyles();
	const theme = useMantineTheme();
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentHighlights, setCurrentHighlights] =
		useRecoilState(HighlightAtom);
	const [currentActions, setCurrentActions] = useRecoilState(
		currentMeetingActions
	);
	const setTimestamp = useSetRecoilState(timestamp);
	const setMobileTimestamp = useSetRecoilState(mobileTimestamp);
	const [playButtonClicked, setPlayButtonClicked] = useRecoilState(
		playButtonClickedAtom
	);
	const user = useRecoilValue(currentUser);
	const flags = useFlags(['highlight_sharing']); // only causes re-render if specified flag values / traits change
	const isSharingEnabled = flags.highlight_sharing.enabled;
	const [isAssigned, setIsAssigned] = useState(
		Boolean(highlight?.assignees?.length)
	);
	const [opened, setOpened] = useState(false);

	const { organizer, owningUserID } = meeting;

	const isOwnerOrSupportUserOrPM =
		isMeetingOwnerOrProjectManager(
			organizer,
			owningUserID,
			user.id,
			user.roles
		) || isSupportUser(user?.roles);
	const isAction = highlight.type === 'action';
	const isEditable =
		highlight.type === 'note'
			? highlight.owningUserID === user?.id
			: isOwnerOrSupportUserOrPM;

	useLayoutEffect(() => {
		setOpened(isAssigned);
	}, [isAssigned]);

	const highlightText = (text: string | undefined) => {
		return reactStringReplace(text, searchValue, (match, i) => (
			<span key={i} style={{ backgroundColor: 'yellow' }}>
				{match}
			</span>
		));
	};
	const highlightContent = searchValue.length
		? highlightText(highlight.content)
		: highlight.content;
	const getBulletPointColor = (highlightType: string) => {
		const foundType = [...momentTypeChipSet].find(
			(type) => type.value === highlightType
		);
		// '#4c6ef5' is the 'indigo' mantine color
		return foundType ? foundType.hexColor : '#4c6ef5';
	};

	const handlePlayButton = ({
		ms,
		highlightID,
	}: {
		ms: number;
		highlightID: string;
	}) => {
		// go to video in ms
		setTimestamp({ time: Math.floor(ms / MILLISECONDS_PER_SECOND) });
		setMobileTimestamp({ time: Math.floor(ms / MILLISECONDS_PER_SECOND) });
		setPlayButtonClicked(!playButtonClicked);
		// update query param for highlight/action id
		setSearchParams({
			highlight: highlightID,
		});
	};

	/**
	 * Remove highlight or action from meeting
	 */
	const deleteHighlight = (highlightID: string | undefined) => {
		const payload = [highlightID];
		destroyMeetingHighlights(highlight.meetingID, payload, organizationID)
			.then((res) => {
				showSuccessNotification({
					message: 'Highlight was successfully destroyed! Great work!',
				});
				const filteredList = currentHighlights.filter(
					(e: IHighlight) => e.id !== res.data.data.meetingHighlightIDs[0]
				);
				setCurrentHighlights(filteredList);
			})
			.catch((err) => {
				logger('error', 'error deleting highlight', err);
				showFailureNotification({
					message:
						"We apologize, but there was an error while deleting the highlight. Please try again, and if the issue persists, don't hesitate to contact our support team for assistance.",
				});
			});
	};

	/**
	 * Remove highlight from meeting
	 */
	const deleteAction = (actionID: string | undefined) => {
		const payload = [actionID];
		destroyMeetingHighlights(highlight.meetingID, payload, organizationID)
			.then((res) => {
				showSuccessNotification({
					message: 'Action was successfully destroyed! Great work!',
				});
				const filteredList = currentActions.filter(
					(e: IAction) => e.id !== res.data.data.meetingHighlightIDs[0]
				);
				setCurrentActions(filteredList);
			})
			.catch((err) => {
				logger('error', 'error deleting action', err);
				showFailureNotification({
					message:
						"We apologize, but there was an error while deleting the action. Please try again, and if the issue persists, don't hesitate to contact our support team for assistance.",
				});
			});
	};

	const deleteHighlightOrAction =
		isAuditPage || !isAction ? deleteHighlight : deleteAction;

	/**
	 * handles the claim/unclaim button on each card.
	 * if assigned, unclaim the action.
	 * if unassigned, claim it.
	 */
	const handleClaimAction = async () => {
		try {
			// when I claim an action, I need to update the action with my user id.
			// if Im an owner already, then I'm unclaiming. If not I'm claiming.
			const newAssignment = await assignMeetingActions({
				meetingActionIDs: [highlight.id],
				userID: isAssigned ? null : user.id,
				meetingID: meeting.id,
				organizationID: organizationID,
			});

			if (newAssignment.data.data) {
				// add assignee to the current action
				const updatedActions = currentActions.map((item: IAction) => {
					if (item.id !== highlight.id) return item;
					return {
						...item,
						assignee: !isAssigned
							? {
									email: user.email,
									firstName: user.firstName,
									id: user.id,
									lastName: user.lastName,
									userAvatarURL: user.userAvatarURL,
							  }
							: undefined,
					};
				});
				setIsAssigned((isAssigned) => !isAssigned);
				setCurrentActions(sortActions(updatedActions, user.id));
			}
		} catch (err) {
			logger('error', 'error assigning action', err);
			showFailureNotification({
				message:
					'Sorry, there was an error assigning the action. Please try again.',
			});
		}
	};

	return (
		<Paper
			key={highlight.id}
			className={classes.paper}
			p='md'
			shadow={'xs'}
			my={'xs'}
		>
			<Stack spacing={'xs'}>
				<Group position={'apart'}>
					<Group position={'left'} spacing={4}>
						<span
							style={{ color: getBulletPointColor(highlight.type) }}
							className={classes.bulletPoint}
						>
							&#8226;
						</span>
						{highlight.isTopQuestion && highlight.type === 'question' ? (
							<Badge color={'lime'} size='sm'>
								Top Question
							</Badge>
						) : (
							<Text className={classes.cardText}>
								{highlightTypeLabels[highlight.type]}
							</Text>
						)}
					</Group>
					<Group position={'right'} spacing={4}>
						{highlight.visibility === 'personal' ? (
							<FontAwesomeIcon icon={faLock} size={'xs'} />
						) : (
							<FontAwesomeIcon icon={faGlobe} size={'xs'} />
						)}
						<Text className={classes.cardText}>
							{visibilityLabels[highlight.visibility]}
						</Text>
					</Group>
				</Group>
				<Group position={'left'}>
					<Text className={classes.contentText}>{highlightContent}</Text>
				</Group>
				<Group position={'apart'} align={'center'}>
					<PlayButtonWithTimestamp
						highlight={highlight}
						handlePlayButton={handlePlayButton}
					/>
					{isSharingEnabled ? (
						<Group position={'apart'} spacing={'sm'}>
							<FontAwesomeIcon
								style={{ cursor: 'pointer' }}
								icon={faCommentDots}
								size={'xs'}
								color={theme.colors['primary-text'][0]}
							/>
							<FontAwesomeIcon
								style={{ cursor: 'pointer' }}
								icon={faPaperPlane}
								size={'xs'}
								color={theme.colors['primary-text'][0]}
							/>
							<FontAwesomeIcon
								style={{ cursor: 'pointer' }}
								icon={faTrash}
								size={'xs'}
								color={theme.colors['red-fill'][0]}
								onClick={() => deleteHighlightOrAction(highlight.id)}
							/>
						</Group>
					) : (
						<Group position={'right'} spacing={'sm'}>
							{isAction && !isAdmin && (
								<>
									{isAssigned ? (
										<Transition
											mounted={opened}
											transition={'slide-right'}
											duration={400}
											exitDuration={400}
											timingFunction={'ease'}
										>
											{(styles) => (
												<Button
													style={styles}
													size={'xs'}
													className={classes.completeButton}
													variant={'outline'}
													radius={'md'}
													onClick={handleClaimAction}
												>
													Unclaim
												</Button>
											)}
										</Transition>
									) : (
										<Button
											size={'xs'}
											className={classes.completeButton}
											variant={'outline'}
											radius={'md'}
											onClick={handleClaimAction}
										>
											Claim
										</Button>
									)}
								</>
							)}
							{isEditable ? (
								<FontAwesomeIcon
									style={{ cursor: 'pointer' }}
									icon={faPencil}
									size={'xs'}
									color={theme.colors['secondary-text'][0]}
									onClick={() => handleEditHighlight(highlight, index)}
								/>
							) : null}
							{isEditable ? (
								<FontAwesomeIcon
									style={{ cursor: 'pointer' }}
									icon={faTrash}
									size={'xs'}
									color={theme.colors['red-fill'][0]}
									onClick={() => deleteHighlightOrAction(highlight.id)}
								/>
							) : null}
						</Group>
					)}
				</Group>
			</Stack>
		</Paper>
	);
};
