import { ScrollArea, Stack } from '@mantine/core';
import { IMeeting } from '../../../../../interfaces/meeting';
import { removeHtmlTags } from '../../../../../_utils/handy-functions';

interface Props {
	meeting: IMeeting;
}

export default function LongSummaryTabContent({ meeting }: Props) {
	const { longSummary } = meeting;

	return (
		<Stack
			h={'100%'}
			w={'100%'}
			style={{
				justifyContent: 'flex-start',
			}}
		>
			<ScrollArea
				p={'md'}
				pb={0}
				lh={'25px'}
				h={'100%'}
				type='always'
				offsetScrollbars
				scrollbarSize={10}
				mih={100}
				styles={{
					root: {
						flex: 2,
					},
					viewport: {
						overflowX: 'hidden',
						width: '100%',
						maxWidth: '100%',
						fontSize: 14,
						fontWeight: 400,
						lineHeight: '25px',
					},
				}}
			>
				{removeHtmlTags(longSummary)}
			</ScrollArea>
		</Stack>
	);
}
