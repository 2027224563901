import {
	Button,
	Group,
	Paper,
	ScrollArea,
	Stack,
	Title,
	Tooltip,
} from '@mantine/core';
import SupportUserCard from '../../components/SupportUserCard';
import OrganizationBranding from './components/OrganizationBranding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPlus } from '@fortawesome/pro-light-svg-icons';
import { Organization } from '../../../../../Atoms/organizationAtom';
import {
	BrandDetails,
	OrganizationSettings,
} from '../../../../../Atoms/settings';
import CustomBranding from './components/CustomBranding';
import MeetingMinutes from './components/MeetingMinutes';
import Sharing from './components/Sharing';
import SharingDefault from './components/SharingDefault';
import InternalEmailDomainsCard from './components/InternalEmailDomainsCard';
import DistributionPreference from './components/DistributionPreference';
import SkipAudit from './components/SkipAudit';
import { saveOrganizationSettings } from '../../../../../api/settings';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';

interface Props {
	open: () => void;
	organization: Organization;
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
	brand: BrandDetails;
	setBrand: React.Dispatch<React.SetStateAction<BrandDetails>>;
}

export default function OrganizationOverview({
	open,
	organization,
	organizationSettings,
	setOrganizationSettings,
	brand,
	setBrand,
}: Props) {
	const { supportLinks, id } = organization;

	const handleSettingChange = async (setting: string, value: any) => {
		try {
			const payload = [
				{
					key: setting,
					value,
				},
			];
			const res = await saveOrganizationSettings(payload, id);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: 'You have successfully changed the default sharing setting.',
			});
		} catch (error) {
			logger('error', 'error changing default sharing', error);
			showFailureNotification({
				message:
					'Sorry, there was an error changing the default sharing setting. Please try again.',
			});
		}
	};

	return (
		<ScrollArea h={'100%'} offsetScrollbars>
			<Stack h={'100%'}>
				<Group position='apart'>
					<Group spacing={'xs'}>
						<Title order={4}>Support Users</Title>
						<Tooltip
							label={
								'Assigning support users to organizations means you will be notified via email whenever meetings reach audit status.'
							}
							withArrow
						>
							<FontAwesomeIcon icon={faCircleInfo} />
						</Tooltip>
					</Group>

					<Button
						type={'submit'}
						variant={'filled'}
						radius={'xl'}
						rightIcon={<FontAwesomeIcon icon={faPlus} />}
						color={'primary'}
						onClick={open}
					>
						Add
					</Button>
				</Group>
				{supportLinks?.length ? (
					supportLinks.map((supportLink, index) => (
						<SupportUserCard supportUser={supportLink} index={index} />
					))
				) : (
					<Paper>
						No assigned support users. Click on the button to the right to add
						some.
					</Paper>
				)}
				<Title order={4}>Meeting Minutes</Title>
				<MeetingMinutes
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
				/>
				<Title order={4}>Distribution Default</Title>
				<DistributionPreference
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
				/>
				<Title order={4}>Sharing</Title>
				<Sharing
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
				/>
				<Title order={4}>Sharing Default</Title>
				<SharingDefault
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
				/>
				<Title order={4}>Internal Email Domains</Title>
				<InternalEmailDomainsCard
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
				/>
				<Title order={4}>Skip Audit</Title>
				<SkipAudit
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					handleSettingChange={handleSettingChange}
				/>
				<Title order={4}>Bot Customization</Title>
				<OrganizationBranding
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
				/>
				<Title order={4}>Custom Branding</Title>
				<CustomBranding
					organizationSettings={organizationSettings}
					setOrganizationSettings={setOrganizationSettings}
					brand={brand}
					setBrand={setBrand}
				/>
			</Stack>
		</ScrollArea>
	);
}
