import { InvitedUser } from '../interfaces/user';

export const deduplicateUsers = (
	users: (InvitedUser | [string | null, string | null, string | null])[]
) => {
	const uniqueUsers = new Map();
	users.forEach((user) => {
		let identifier = '';
		if (Array.isArray(user)) {
			identifier = user.join('-'); // Combine all elements of the array to form a unique identifier
		} else if (typeof user === 'object') {
			identifier = user.id;
		}
		if (identifier && !uniqueUsers.has(identifier)) {
			uniqueUsers.set(identifier, user);
		}
	});
	return Array.from(uniqueUsers.values());
};

export const applySearchFilter = (list, value) => {
	return list.filter((user) => {
		let email = '';
		let firstName = '';
		let lastName = '';
		if (Array.isArray(user)) {
			email = user[0];
			firstName = user[1];
			lastName = user[2];
		} else if (typeof user === 'object') {
			email = user.email;
			firstName = user.firstName;
			lastName = user.lastName;
		}
		const fullName = `${firstName} ${lastName}`.toLowerCase();
		return (
			(email || '').toLowerCase().includes(value.toLowerCase()) ||
			fullName.includes(value.toLowerCase())
		);
	});
};
