import {
	ActionIcon,
	Group,
	Paper,
	Select,
	Stack,
	Text,
	Tooltip,
	createStyles,
} from '@mantine/core';
import PlatformIcon from '../../PlatformIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentText } from '@fortawesome/pro-light-svg-icons';
import {
	pauseRecallBotRecording,
	resumeRecallBotRecording,
} from '../../../api/playPauseRecording';
import { currentUser } from '../../../Atoms/userAtoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IMeeting } from '../../../interfaces/meeting';
import { liveMeetingsWithBots } from '../../../Atoms/recallAI';
import { useState } from 'react';
import { logger } from '../../../helpers/logger';
import PlayPauseButton from '../../PlayPauseButton';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { DateTime } from 'luxon';
import { faClockTen } from '@fortawesome/pro-regular-svg-icons';
import { BotStatus, BotStatusMapping } from '../../../interfaces/recallai';
import { parseDateTime } from '../../../helpers/date';
const useStyles = createStyles((theme) => ({
	container: {
		border: `1px solid ${theme.colors['secondary-text'][0]}`,
		borderRadius: theme.spacing.md,
		padding: theme.spacing.xs,
	},
	top: {
		borderBottom: `1px solid #DADCE0`,
	},
}));

interface Props {
	meeting: IMeeting;
}

export default function MeetingRecordingItem({ meeting }: Props) {
	const { classes } = useStyles();
	const user = useRecoilValue(currentUser);
	const setLiveMeetings = useSetRecoilState(liveMeetingsWithBots);
	const [loading, setLoading] = useState(false);
	const { platform, botMetadata, name } = meeting;
	const recording = botMetadata.status === BotStatus.InCallRecording;
	const botStatus = BotStatusMapping[botMetadata?.status];
	const botInCall =
		botMetadata.status === BotStatus.InCallNotRecording ||
		botMetadata.status === BotStatus.InCallRecording;

	const resumeRecording = async () => {
		try {
			setLoading(true);
			const res = await resumeRecallBotRecording(
				botMetadata?.id,
				user.currentOrganizationID
			);
			showSuccessNotification({
				title: 'Recording Resumed',
				message: `Live recording for ${name} has resumed.`,
			});
		} catch (e) {
			logger('error', 'resumeRecording', e);
			showFailureNotification({
				message: `Sorry, live recording for ${name} has failed to resume. Please try again.`,
			});
		} finally {
			setLoading(false);
		}

		setLiveMeetings((prev) =>
			prev.map((item) => {
				if (item.id === meeting.id) {
					return {
						...item,
						botMetadata: {
							...item.botMetadata,
							status: BotStatus.InCallRecording,
						},
					};
				}
				return item;
			})
		);
	};

	const pauseRecording = async () => {
		try {
			setLoading(true);
			const res = await pauseRecallBotRecording(
				botMetadata?.id,
				user.currentOrganizationID
			);
			showSuccessNotification({
				title: 'Recording paused',
				message: `Live recording for ${name} has paused.`,
			});
		} catch (e) {
			showFailureNotification({
				message: `Sorry, live recording for ${name} has failed to pause. Please try again.`,
			});
			logger('error', 'pauseRecording', e);
		} finally {
			setLoading(false);
		}

		setLiveMeetings((prev) =>
			prev.map((item) => {
				if (item.id === meeting.id) {
					return {
						...item,
						botMetadata: {
							...item.botMetadata,
							status: BotStatus.InCallNotRecording,
						},
					};
				}
				return item;
			})
		);
	};

	return (
		<Paper
			shadow='sm'
			radius={'lg'}
			sx={{
				border: '1px solid #DADCE0',
			}}
		>
			<Stack h={'100%'} w={'100%'} py={'xs'}>
				<Group
					className={classes.top}
					w={'100%'}
					noWrap
					position='apart'
					pb={'xs'}
					px={'sm'}
					spacing={'xs'}
				>
					<Group
						spacing={5}
						noWrap
						align='center'
						style={{
							flex: 1,
						}}
					>
						<FontAwesomeIcon icon={faClockTen} />
						<Text fz={12} mt={2}>
							{parseDateTime(
								botMetadata?.joinAt || meeting?.startAt || botMetadata.createdAt
							)?.toFormat('h:mma')}
						</Text>
					</Group>
					<Group
						spacing={5}
						noWrap
						align='center'
						position='right'
						style={{
							flex: 1,
						}}
					>
						<ActionIcon
							variant={'transparent'}
							onClick={() => window.open(botMetadata?.meetingLink, '_blank')}
							size={20}
						>
							<PlatformIcon
								platform={meeting.platform}
								marginTop={3}
								size={16}
							/>
						</ActionIcon>
						<Text
							fz={12}
							mt={2}
							color='secondary-blue'
							component='a'
							target='_blank'
							href={botMetadata?.meetingLink}
						>
							Join Meeting
						</Text>
					</Group>
				</Group>
				<Group spacing={0} w={'100%'} noWrap position='apart' px={'sm'}>
					<Text
						color='primary'
						fz={12}
						fw={700}
						style={{
							flex: 1,
						}}
					>
						{name}
					</Text>
					<Group
						w={100}
						align='center'
						noWrap
						position='right'
						spacing={5}
						style={{
							flex: 1,
						}}
					>
						{botInCall ? (
							<PlayPauseButton
								recording={recording}
								pauseRecording={pauseRecording}
								resumeRecording={resumeRecording}
							/>
						) : null}

						<Text fz={12} fw={500} fs={'italic'}>
							{botStatus}
						</Text>
					</Group>
				</Group>
			</Stack>
		</Paper>
	);
}
