import { adminRoles, customerAdminRoles, roles } from '../components/constants';
import { IOrganizer } from '../interfaces/meeting';

// for support side
export const isAdmin = (roles: string[]) =>
	roles.some((role: string) => adminRoles.includes(role));

export const isSupportUser = (roles: string[]) =>
	roles.some((role: string) => role === 'support');

export const isUserAdmin = (roles: string[]) =>
	roles.some((role: string) => customerAdminRoles.includes(role));

export const isSuperadmin = (roles: string[]) =>
	roles.some((role: string) => role === 'superadmin');

export const isProjectManager = (roles: string[]) =>
	roles.some((role: string) => role === 'project-manager');

/** */
export const isMeetingOwnerOrProjectManager = (
	organizer: IOrganizer,
	owningUserID,
	userID: string,
	roles: string[]
) => {
	// if organizer is null, then this is not a calendar event.
	// use owningUserID instead.
	return (
		roles.includes('project-manager') ||
		(organizer ? organizer?.userID : owningUserID) === userID
	);
};
