import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	Group,
	Tabs,
	Text,
	createStyles,
	px,
	useMantineTheme,
} from '@mantine/core';
import SummaryTabContent from './tabcontents/SummaryTabContent';
import TranscriptTabContent from './tabcontents/TranscriptTabContent';
import ChatTabContent from './tabcontents/ChatTabContent';
import AttendeesTabContent from './tabcontents/AttendeesTabContent';
import { IMeeting } from '../../../../interfaces/meeting';
import {
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useRef,
	useState,
} from 'react';
import ExpandedAttendeesView from '../MeetingLeftCard/components/ExpandedAttendeesView';
import ExpandedTranscriptView from '../MeetingLeftCard/components/ExpandedTranscriptView';
import ExpandedChatView from '../MeetingLeftCard/components/ExpandedChatView';
import { useMediaQuery } from 'react-responsive';
import useMediaQueries from '../../../../customHooks/useMediaQueries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/pro-solid-svg-icons';
import { meetingAttachments } from '../../../../Atoms/meetingAtom';
import { useRecoilValue } from 'recoil';
import LongSummaryTabContent from './tabcontents/LongSummaryTabContent';
import { isAuthenticated } from '../../../../Atoms/auth';

const tabPaths = [
	{ label: 'Short Story', path: 'summary' },
	{ label: 'Long Story', path: 'longSummary' },
	{ label: 'Transcript', path: 'transcript' },
	// { label: 'Chat', path: 'chat' },
	{ label: 'Attendees', path: 'attendees' },
];

const TABS_LIST_HEIGHT_PX = 51.32;

const useStyles = createStyles((theme) => ({
	tab: {
		color: '#8C919B',
	},
	tabSelected: {
		color: theme.fn.primaryColor(),
	},
	enabled: {
		cursor: 'pointer',
	},
	actionButton: {
		cursor: 'pointer',
	},
}));

interface Props {
	meeting: IMeeting;
	inviteUser?: ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => void;
	playerRef: MutableRefObject<HTMLVideoElement | null>;
	openMinutesModal: () => void;
}

function MeetingDetailsTabSection({
	meeting,
	inviteUser,
	playerRef,
	openMinutesModal,
}: Props) {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const tabsListRef = useRef<HTMLDivElement>(null);
	const { meetingID, tabValue = 'summary' } = useParams();
	const { summary, longSummary } = meeting;
	const [transcriptSearchValue, setTranscriptSearchValue] = useState('');
	const [chatSearchValue, setChatSearchValue] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const { heightLessThanLG, widthOrHeightLessThanLG } = useMediaQueries();
	const attachments = useRecoilValue(meetingAttachments);
	const minutesDoc = attachments.find(
		(attachment) =>
			attachment.type === 'minutes' || attachment.fileName.includes('Minutes')
	);
	const [searchParams] = useSearchParams();
	const sharingToken = searchParams.get('vt');
	const isAuthorized = useRecoilValue(isAuthenticated);

	const handleTabChange = (value) => {
		let navPath = `/meetings/${meetingID}/${value}`;
		if (sharingToken) {
			navPath += `?vt=${sharingToken}`;
		}
		navigate(navPath);
	};

	const renderTabContent = (tabPath) => {
		if (tabPath === 'summary') return <SummaryTabContent meeting={meeting} />;
		if (tabPath === 'longSummary')
			return <LongSummaryTabContent meeting={meeting} />;
		if (tabPath === 'transcript')
			return (
				<TranscriptTabContent
					searchValue={transcriptSearchValue}
					setSearchValue={setTranscriptSearchValue}
				/>
			);
		// if (tabPath === 'chat')
		// 	return (
		// 		<ChatTabContent
		// 			searchValue={chatSearchValue}
		// 			setSearchValue={setChatSearchValue}
		// 		/>
		// 	);
		if (tabPath === 'attendees')
			return <AttendeesTabContent inviteUser={inviteUser} />;
	};

	return (
		<>
			<Tabs
				value={tabValue}
				onTabChange={handleTabChange}
				styles={(theme) => ({
					tab: {
						fontSize: 14,
						padding: widthOrHeightLessThanLG ? 10 : 12,
						borderBottomWidth: `3px`,
						fontWeight: 700,
						color: '#8C919B',
					},
					root: {
						flex: 2,
						overflow: 'hidden',
						paddingBottom: 16,
					},
					panel: {
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					},
				})}
			>
				<Tabs.List ref={tabsListRef}>
					<Group position='apart' noWrap w={'100%'}>
						<Group noWrap spacing={0}>
							{tabPaths.map((tabPath) => (
								<Tabs.Tab value={tabPath.path} key={tabPath.path}>
									<span
										style={{
											color:
												tabValue === tabPath.path
													? theme.fn.primaryColor()
													: '#8C919B',
										}}
									>
										{tabPath.label}
									</span>
								</Tabs.Tab>
							))}
						</Group>

						{minutesDoc && isAuthorized ? (
							<Group
								noWrap
								spacing={'xs'}
								align='center'
								className={classes.actionButton}
								onClick={(e) => {
									e.stopPropagation();
									openMinutesModal();
								}}
							>
								<FontAwesomeIcon
									icon={faFileLines}
									color='#3B3591'
									size='lg'
									className={classes.enabled}
								/>
								<Text size={14} fw={700} color='primary-cta'>
									Meeting Minutes
								</Text>
							</Group>
						) : null}
					</Group>
				</Tabs.List>

				{tabPaths.map((tabPath) => (
					<Tabs.Panel
						value={tabPath.path}
						key={tabPath.path}
						mah={`calc(100% - ${
							tabsListRef.current
								? tabsListRef.current.clientHeight
								: TABS_LIST_HEIGHT_PX
						}px)`}
					>
						{renderTabContent(tabPath.path)}
					</Tabs.Panel>
				))}
			</Tabs>
			{tabValue === 'attendees' ? (
				<ExpandedAttendeesView
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					inviteUser={inviteUser}
				/>
			) : null}
			{tabValue === 'transcript' ? (
				<ExpandedTranscriptView
					searchValue={transcriptSearchValue}
					setSearchValue={setTranscriptSearchValue}
					playerRef={playerRef}
				/>
			) : null}
			{/* {tabValue === 'chat' ? (
				<ExpandedChatView
					searchValue={chatSearchValue}
					setSearchValue={setChatSearchValue}
				/>
			) : null} */}
		</>
	);
}

export default MeetingDetailsTabSection;
