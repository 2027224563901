import { Group, Paper, Select, Switch, Text } from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import { useEffect, useState } from 'react';
import { logger } from '../../../../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { saveOrganizationSettings } from '../../../../../../../api/settings';
import { useParams } from 'react-router';

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
}

const data = [
	{ value: 'none', label: 'None' },
	{ value: 'attendees', label: 'Attendees' },
	{ value: 'invitees', label: 'Invitees' },
	{ value: 'all', label: 'All' },
];

export default function DistributionPreference({
	organizationSettings,
	setOrganizationSettings,
}: Props) {
	const { classes } = styles();
	const { organizationID } = useParams();
	const { distribution_preference: distributionPreference } =
		organizationSettings;
	const [value, setValue] = useState<string>(distributionPreference);

	useEffect(() => {
		setValue(distributionPreference);
	}, [distributionPreference]);

	const handleChange = async (value: string) => {
		try {
			setValue(value);
			const payload = [
				{
					key: 'distribution_preference',
					value,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message:
					'You have successfully changed the distribution preference setting.',
			});
		} catch (error) {
			logger('error', 'error changing distribution_preference', error);
			showFailureNotification({
				message:
					'Sorry, there was an error changing the distribution preference setting. Please try again.',
			});
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Group position='apart' noWrap>
				<Text mb={4} size={'sm'}>
					This dropdown allows you to define which users of a calendar event
					automatically receive published reelays. The options are: None - no
					one is added automatically; Attendees - all meeting attendees who are
					verified Reelay users; Invitees - all calendar invitees who are
					verified Reelay users; and All - all calendar invitees. This setting
					streamlines the distribution of published reelays based on your
					selected criteria.
				</Text>
				<Select
					withinPortal
					rightSection={null}
					value={value}
					onChange={(val) => handleChange(val)}
					data={data}
					maw={150}
					miw={150}
					radius={'lg'}
					size='sm'
					style={{
						flex: 1,
					}}
				/>
			</Group>
		</Paper>
	);
}
