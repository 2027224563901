import {
	ActionIcon,
	Center,
	CopyButton,
	Flex,
	Group,
	Menu,
	Paper,
	Text,
	Tooltip,
	createStyles,
} from '@mantine/core';
import { Chapter } from '../../../../../helpers/meetings';
import { IAction } from '../../../../../interfaces/action';
import { IHighlight } from '../../../../../interfaces/highlight';
import { MILLISECONDS_PER_SECOND } from '../../../../../components/constants';
import { formatSecondsToMinutesAndSeconds } from '../../../../../_utils/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faCopy,
	faEllipsisVertical,
	faEye,
	faFolderMinus,
	faFolderPlus,
	faMinus,
	faPencil,
	faPlus,
	faTrash,
	faUserGroup,
	faUserPen,
	faX,
} from '@fortawesome/pro-regular-svg-icons';
import { Ref, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	User,
	currentUser,
	editingHighlightAtom,
	editingHighlightID,
	editingHighlightInitialValuesAtom,
} from '../../../../../Atoms/userAtoms';
import {
	assignActionModalOpenState,
	assigningActionAtom,
	currentMeeting,
	currentMeetingActions,
	currentMeetingChapters,
	currentMeetingHighlights,
	momentDialogOpened,
} from '../../../../../Atoms/meetingAtom';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import {
	sortActionsByTimeStartMS,
	sortHighlightsByTimeStartMS,
} from '../../../../../helpers/highlights';
import { logger } from '../../../../../helpers/logger';
import {
	assignMeetingActions,
	destroyMeetingChapter,
	destroyMeetingHighlights,
	markActionItems,
} from '../../../../../api/api';
import { DateTime } from 'luxon';
import { isAssignedToUser } from '../../../../../helpers/actions';
import { currentActions } from '../../../../../Atoms/actions';
import SeeMoreTopic from './SeeMoreTopic';
import AvatarGroup from '../../../../../components/AvatarGroup';
import { isAuthenticated } from '../../../../../Atoms/auth';
import { isMeetingOwnerOrProjectManager } from '../../../../../helpers/auth';

const useStyles = createStyles((theme) => ({
	timestampContainer: {
		padding: '6px 10px',
		backgroundColor: '#DDE7E9',
		borderRadius: 8,
		fontWeight: 700,
		fontSize: 14,
		cursor: 'pointer',
		':hover': {
			backgroundColor: theme.fn.primaryColor(),
			color: 'white',
		},
		width: '71.7px',
		textAlign: 'center',
	},
	active: {
		backgroundColor: theme.fn.primaryColor(),
		color: 'white',
	},
	contentAndMenu: {
		borderBottom: '1px solid #D3D9DD',
	},
	chapterBar: {
		cursor: 'pointer',
		// border: `1px solid ${theme.colors.primary[0]}`,
	},
	title: {
		color: theme.colors['primary-text'],
		fontWeight: 600,
		fontSize: 14,
		lineHeight: 1.55,
	},
	activeChapter: {
		// border: `1px solid ${theme.colors?.['primary'][0]}`,
		cursor: 'pointer',
		backgroundColor: 'rgba(79, 70, 229, 0.1)',
		borderRadius: 8,
	},
	claimedText: {
		fontWeight: 600,
		fontSize: 10,
		lineHeight: '14px',
		color: theme.colors?.primary[5],
	},
}));

interface Props {
	item: IHighlight | IAction | Chapter;
	index: number;
	handleMomentClick: (time: number, index: number) => void;
	handleEditChapter: (chapter: Chapter) => void;
	scrollRef: Ref<HTMLDivElement>;
	activeIndex: number;
}

export default function MomentRow({
	item,
	index,
	handleMomentClick,
	handleEditChapter,
	scrollRef,
	activeIndex,
}: Props) {
	const { theme, classes } = useStyles();
	const meeting = useRecoilValue(currentMeeting);
	const menuButtonRef = useRef(null); // Reference to the menu button to position the div accordingly
	const time = 'time' in item ? item.time : item.timeStartMS;
	const isChapter = !('type' in item);
	const { organizationID, name, author, meetingDate, publishedAt, createdAt } =
		meeting;
	const {
		timeStartMS,
		content,
		type,
		id,
		meetingID,
		isTopQuestion,
		actionableStatus = 'todo',
		assignees,
	} = item as any;
	const { organizer, owningUserID } = meeting;
	const user = useRecoilValue(currentUser);
	const assignedToYou = isAssignedToUser(assignees, user.id);
	const isMeetingHostOrPM = isMeetingOwnerOrProjectManager(
		organizer,
		owningUserID,
		user.id,
		user.roles
	);

	const setEditingHighlight = useSetRecoilState(editingHighlightAtom);
	const [currentHighlights, setCurrentHighlights] = useRecoilState(
		currentMeetingHighlights
	);
	const [chapters, setChapters] = useRecoilState(currentMeetingChapters);
	const setEditingHighlightInitialValues = useSetRecoilState(
		editingHighlightInitialValuesAtom
	);
	const setEditingHighlightID = useSetRecoilState(editingHighlightID);
	const setAssignActionModalOpened = useSetRecoilState(
		assignActionModalOpenState
	);
	const setAssigningAction = useSetRecoilState(assigningActionAtom);
	const [seeMoreOpened, setSeeMoreOpened] = useState(false);
	const [dialogOpened, setDialogOpened] = useRecoilState(momentDialogOpened);
	const dateToUse = DateTime.fromISO(
		meetingDate || publishedAt || createdAt
	).toFormat('MM/dd/yyyy');
	const copyableActionContent = `Check out this action item from a meeting hosted by ${author?.firstName} ${author?.lastName} on ${dateToUse}

	Meeting Name: ${name}
	Action Item: "${content}"`;
	const authenticated = useRecoilValue(isAuthenticated);

	const handleEditHighlight = (highlight: IHighlight | IAction) => {
		const { content, type, visibility, timeStartMS, id, isTopQuestion } =
			highlight;
		// sets the overlay
		setEditingHighlight(true);
		// open edit highlight dialog or note dialog
		setDialogOpened(true);
		// prefill form value and edit card
		setEditingHighlightInitialValues({
			content,
			type,
			visibility,
			timeStartMS,
			isTopQuestion,
		});
		// set index to get highlight info inside card
		setEditingHighlightID(id);
	};

	/**
	 * Remove highlight or action from meeting
	 */
	const deleteHighlight = async (highlightID: string | undefined) => {
		try {
			const payload = [highlightID];
			const res = await destroyMeetingHighlights(
				meetingID,
				payload,
				organizationID
			);
			const deletedHighlightIDs = res.data.data.meetingHighlightIDs;
			const updatedHighlights = currentHighlights.filter(
				(e: IAction) => !deletedHighlightIDs.includes(e.id)
			);
			showSuccessNotification({
				message: 'Highlight was successfully destroyed! Great work!',
			});
			setCurrentHighlights(updatedHighlights);
		} catch (err) {
			logger('error', 'error deleting moment', err);
			showFailureNotification({
				message:
					"We apologize, but there was an error while deleting the moment. Please try again, and if the issue persists, don't hesitate to contact our support team for assistance.",
			});
		}
	};

	const deleteChapter = async (chapterID: string | undefined) => {
		try {
			const res = await destroyMeetingChapter(
				meeting.id,
				item.id,
				meeting.organizationID
			);
			setChapters((prev) => prev.filter((c) => c.id !== chapterID));
			showSuccessNotification({
				message: 'Meeting topic was successfully deleted!',
			});
		} catch (err) {
			logger('error', 'deleting chapter', err);
			showFailureNotification({
				message: 'Failed to delete meeting topic. Please try again.',
			});
		}
	};

	/**
	 * handles the claim/unclaim button on each card.
	 * if you are already assigned to this action, then unclaim it by passing userID: null
	 * if unassigned, claim it by passing userID: user.id
	 */
	const handleClaimAction = async () => {
		try {
			// when I claim an action, I need to update the action with my user id.
			// if Im an owner already, then I'm unclaiming. If not I'm claiming.
			const userID = assignedToYou ? null : user.id;
			const newAssignment = await assignMeetingActions({
				meetingActionIDs: [id],
				userID,
				meetingID: meeting.id,
				organizationID,
			});
			if (newAssignment.data.data) {
				// add new assignee to the current action
				const updatedHighlights = currentHighlights.map((item: IAction) => {
					if (item.id !== id) return item;
					// if userID is null then remove the user from the assignees list
					// if it is not null then add the user to the assignees list
					const assignees = userID
						? [
								...(Array.isArray(item.assignees) ? item.assignees : []),
								{
									email: user.email,
									firstName: user.firstName,
									id: user.id,
									lastName: user.lastName,
									priority: user.priority,
									userAvatarURL: user.userAvatarURL,
								},
						  ]
						: item.assignees.filter((assignee) => assignee.id !== user.id);
					return {
						...item,
						assignees,
					};
				});
				setCurrentHighlights(sortActionsByTimeStartMS(updatedHighlights));
				showSuccessNotification({
					message: userID
						? 'You claimed an action. Great job!'
						: 'Action unclaimed',
				});
			}
		} catch (err) {
			logger('error', 'error assigning action', err);
			showFailureNotification({
				message:
					'Sorry, we were unable to claim the action. Please try again or contact our support team for assistance.',
			});
		}
	};

	const generateClaimedByText = (assignees: User[], userID: string) => {
		let text: string;
		// sort assignees with user first
		const sortedAssignees = [...(assignees || [])].sort((a, b) => {
			if (a.id === userID) {
				return -1; // userID comes first
			} else if (b.id === userID) {
				return 1; // userID comes second
			}
			return 0; // maintain the order for other assignees
		});
		const tooltipLabel = sortedAssignees
			.slice(2)
			.map((assignee) => `${assignee.firstName} ${assignee.lastName}`)
			.join(', ');

		if (!sortedAssignees || sortedAssignees?.length === 0) {
			text = '';
		} else if (sortedAssignees?.length === 1) {
			text =
				sortedAssignees[0].id === userID
					? 'Claimed by You'
					: `Claimed by ${sortedAssignees[0].firstName} ${sortedAssignees[0].lastName}`;
		} else if (sortedAssignees.length === 2) {
			// if the user is one of the assignees, then do this.
			if (sortedAssignees.some((assignee) => assignee?.id === userID)) {
				const otherAssignee = sortedAssignees.find(
					(assignee) => assignee?.id !== userID
				);
				text = `Claimed by You and ${otherAssignee?.firstName} ${otherAssignee?.lastName}`;
			} else {
				text = `Claimed by ${sortedAssignees[0].firstName} ${sortedAssignees[0].lastName} and ${sortedAssignees[1].firstName} ${sortedAssignees[1].lastName}`;
			}
		} else {
			// if it's greater than 2, then return this.
			const yourAssigned = sortedAssignees.some(
				(assignee) => assignee?.id === userID
			);
			if (yourAssigned) {
				text = `Claimed by You, ${sortedAssignees[1].firstName} ${sortedAssignees[1].lastName}, and `;
			} else {
				text = `Claimed by ${sortedAssignees[0].firstName} ${sortedAssignees[0].lastName}, ${sortedAssignees[1].firstName} ${sortedAssignees[1].lastName}, and `;
			}
		}

		if (sortedAssignees?.length > 2)
			return (
				<Text className={classes.claimedText}>
					{text}
					<Tooltip label={tooltipLabel} withArrow>
						<Text span style={{ cursor: 'pointer' }}>
							{`${sortedAssignees.length - 2} ${
								sortedAssignees.length - 2 === 1 ? 'other' : 'others'
							}`}
						</Text>
					</Tooltip>
				</Text>
			);

		return <Text className={classes.claimedText}>{text}</Text>;
	};

	const markAsDone = async (isDone: boolean) => {
		try {
			const actionableStatus = isDone ? 'done' : 'todo';
			const res = await markActionItems(
				[id],
				actionableStatus,
				meetingID,
				organizationID
			);
			const meetingActionID = res?.data?.data?.meetingActionIDs[0];

			if (meetingActionID) {
				const updatedHighlights = currentHighlights.map(
					(item: IAction | IHighlight) => {
						if (item.id !== meetingActionID) return item;
						return {
							...item,
							actionableStatus,
						};
					}
				);
				setCurrentHighlights(
					sortHighlightsByTimeStartMS(
						updatedHighlights as (IHighlight | IAction)[]
					)
				);
				showSuccessNotification({
					message: `Action marked as ${actionableStatus}. Great job!`,
				});
			}
		} catch (err) {
			logger('error', 'error marking action', err);
			showFailureNotification({
				message:
					"Sorry, we were unable to update the action's status. Please try again or contact our support team for assistance.",
			});
		}
	};

	const handleAssign = () => {
		setAssigningAction(item);
		setAssignActionModalOpened(true);
	};

	return (
		<Group
			ref={scrollRef}
			noWrap
			w={'100%'}
			p={'sm'}
			pl={0}
			spacing={'lg'}
			align='flex-start'
		>
			<div
				className={classes.timestampContainer}
				onClick={() => handleMomentClick(index, time)}
				style={{
					backgroundColor:
						activeIndex === index ? theme.fn.primaryColor() : '#DDE7E9',
					color: activeIndex === index ? 'white' : 'black',
				}}
			>
				{formatSecondsToMinutesAndSeconds(time / MILLISECONDS_PER_SECOND)}
			</div>
			<Group
				position='apart'
				noWrap
				align={'start'}
				pl={'sm'}
				pb={'md'}
				style={{
					flex: 2,
				}}
				className={classes.contentAndMenu}
			>
				<Text fw={400} size={14} lh={'25px'}>
					{item.content}
				</Text>

				{!authenticated ? null : !isChapter && type === 'action' ? (
					<Menu
						shadow='md'
						width={275}
						zIndex={500000}
						transitionProps={{ transition: 'pop' }}
						position='bottom-end'
						withinPortal
					>
						<Menu.Target>
							<ActionIcon onClick={(e) => e.stopPropagation()} pt={0}>
								<FontAwesomeIcon
									icon={faEllipsisVertical}
									style={{
										color: theme.colors['secondary-text'][0],
										cursor: 'pointer',
									}}
								/>
							</ActionIcon>
						</Menu.Target>

						<Menu.Dropdown>
							{assignees?.length > 0 ? (
								<>
									<Menu.Label>Assignees</Menu.Label>
									<AvatarGroup
										users={assignees}
										paddingLeft={22}
										showCount={8}
									/>
								</>
							) : null}
							<Menu.Label>Actions</Menu.Label>
							<Menu.Item
								onClick={(e) => {
									e.stopPropagation();
									handleClaimAction();
								}}
								icon={
									<FontAwesomeIcon
										style={{
											color: theme.colors['secondary-text'][0],
											cursor: 'pointer',
										}}
										icon={assignedToYou ? faFolderMinus : faFolderPlus}
										size={'sm'}
									/>
								}
							>
								{assignedToYou ? 'Unclaim' : 'Claim'}
							</Menu.Item>
							<CopyButton value={copyableActionContent} timeout={2000}>
								{({ copy }) => (
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													color: theme.colors['secondary-text'][0],
													cursor: 'pointer',
												}}
												icon={faCopy}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											showSuccessNotification({
												message: 'Action item copied to clipboard!',
											});
											copy();
										}}
									>
										Copy
									</Menu.Item>
								)}
							</CopyButton>

							{isMeetingHostOrPM ? (
								<>
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													color: theme.colors['secondary-text'][0],
													cursor: 'pointer',
												}}
												icon={faUserPen}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											handleAssign();
										}}
									>
										Assign
									</Menu.Item>
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													color: theme.colors['secondary-text'][0],
													cursor: 'pointer',
												}}
												icon={faUserGroup}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											handleAssign();
										}}
									>
										Assign to Multiple People
									</Menu.Item>
								</>
							) : null}

							<Menu.Divider />

							{isMeetingHostOrPM || assignedToYou ? (
								<Menu.Label>Manage</Menu.Label>
							) : null}
							{isMeetingHostOrPM || assignedToYou ? (
								<Menu.Item
									icon={
										<FontAwesomeIcon
											style={{
												color: theme.colors['secondary-text'][0],
												cursor: 'pointer',
											}}
											icon={actionableStatus === 'todo' ? faCheck : faX}
											size={'sm'}
										/>
									}
									onClick={(e) => {
										e.stopPropagation();
										markAsDone(actionableStatus === 'todo');
									}}
								>
									{actionableStatus === 'todo'
										? 'Mark as done'
										: 'Mark as to do'}
								</Menu.Item>
							) : null}

							{isMeetingHostOrPM ? (
								<Menu.Item
									icon={
										<FontAwesomeIcon
											style={{
												color: theme.colors['secondary-text'][0],
												cursor: 'pointer',
											}}
											icon={faPencil}
											size={'sm'}
										/>
									}
									onClick={(e) => {
										e.stopPropagation();
										handleEditHighlight(item);
									}}
								>
									Edit
								</Menu.Item>
							) : null}

							{isMeetingHostOrPM ? (
								<Menu.Item
									icon={
										<FontAwesomeIcon
											style={{
												color: theme.colors['secondary-text'][0],
												cursor: 'pointer',
											}}
											icon={faTrash}
											size={'sm'}
										/>
									}
									onClick={(e) => {
										e.stopPropagation();
										deleteHighlight(id);
									}}
								>
									Delete
								</Menu.Item>
							) : null}
						</Menu.Dropdown>
					</Menu>
				) : isMeetingHostOrPM ? (
					<div style={{ position: 'relative' }}>
						{/* Parent div for positioning see more popover */}
						<Menu
							shadow='md'
							width={275}
							zIndex={500000}
							transitionProps={{ transition: 'pop' }}
							position='bottom-end'
							withinPortal
						>
							<Menu.Target>
								<ActionIcon onClick={(e) => e.stopPropagation()} pt={0}>
									<FontAwesomeIcon
										icon={faEllipsisVertical}
										style={{
											color: theme.colors['secondary-text'][0],
											cursor: 'pointer',
										}}
									/>
								</ActionIcon>
							</Menu.Target>

							<Menu.Dropdown>
								<Menu.Label>Manage</Menu.Label>
								{isMeetingHostOrPM ? (
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													cursor: 'pointer',
												}}
												icon={faPencil}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											isChapter
												? handleEditChapter(item as Chapter)
												: handleEditHighlight(item);
										}}
									>
										Edit
									</Menu.Item>
								) : null}

								{isMeetingHostOrPM ? (
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													cursor: 'pointer',
												}}
												icon={faTrash}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											isChapter ? deleteChapter(id) : deleteHighlight(id);
										}}
									>
										Delete
									</Menu.Item>
								) : null}

								<Menu.Divider />

								{isChapter && (
									<Menu.Item
										icon={
											<FontAwesomeIcon
												style={{
													cursor: 'pointer',
												}}
												icon={faEye}
												size={'sm'}
											/>
										}
										onClick={(e) => {
											e.stopPropagation();
											setSeeMoreOpened(true);
										}}
									>
										See More...
									</Menu.Item>
								)}
							</Menu.Dropdown>
						</Menu>
						{isChapter && seeMoreOpened && (
							<SeeMoreTopic
								topic={item as Chapter}
								setSeeMoreOpened={setSeeMoreOpened}
							/>
						)}
					</div>
				) : null}
			</Group>
		</Group>
	);
}
