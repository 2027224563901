import React, { useEffect, useRef, useState } from 'react';
import {
	Anchor,
	Button,
	Checkbox,
	Divider,
	Group,
	Image,
	Modal,
	Stack,
	Text,
	UnstyledButton,
	useMantineTheme,
} from '@mantine/core';
import useUserSettings from '../../../customHooks/useUserSettings';
import TermsOfUseCheckbox from '../../Checkboxes/TermsOfUseCheckbox';
import { terms_icon } from '../../../assets/images/s3_images';
import TimezoneSelect from '../../Dropdowns/TimezoneSelect';
import { logger } from '../../../helpers/logger';
import { SETTINGS_USER } from '../../../helpers/settings/settings.constants';
import ModalTitle from '../../Titles/ModalTitle';
import { showFailureNotification } from '../../../helpers/notifications';
import { error } from 'console';
import { set } from 'lodash';
import { calendarIntegrationList } from '../../constants';
import IntegrationCard from '../../integration_card';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/pro-regular-svg-icons';

// const duration = 200;

// const defaultStyle = {
// 	transition: `width ${duration}ms ease-out`,
// 	width: '408px',
// };

// const transitionStyles = {
// 	entering: { width: '408px' },
// 	entered: { width: '662px' },
// 	exiting: { width: '662px' },
// 	exited: { width: '408px' },
// };

interface Props {
	opened: boolean;
	close: () => void;
	// openCalendarModal: () => void;
}

export default function TermsOfUseModal({
	opened,
	close,
}: // openCalendarModal,
Props) {
	const theme = useMantineTheme();
	const nodeRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [dontAskAgainChecked, setDontAskAgainChecked] = useState(false);
	const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const { updateUserSettings, userSettings } = useUserSettings();
	const [value, setValue] = useState<string | null>(
		userSettings?.time_zone || tz
	);
	const [page, setPage] = useState(0);

	const handleAgreeClick = async () => {
		try {
			setLoading(true);
			await updateUserSettings([
				{
					key: 'agreed_with_terms',
					value: true,
				},
				{
					key: SETTINGS_USER.TIME_ZONE,
					value,
				},
			]);
			setLoading(false);
			setPage(1);
			localStorage.setItem('congratulate_onboarding_calendar_sync', 'true');
			// close();
			// openCalendarModal();
		} catch (error) {
			showFailureNotification({
				message: 'Failed to save time zone. Please try again.',
			});
			console.log('handleAgreeClick error', error);
			logger(
				'error',
				'Failed to save time zone and terms. Please try again.',
				error
			);
		}
	};

	const handleNotYet = async () => {
		// check of dont ask again is checked. if checked then set that as well
		if (dontAskAgainChecked) {
			const res = await updateUserSettings([
				{
					key: 'calendar_modal_dont_ask_again',
					value: true,
				},
			]);
		}
		// set the local storage value ahead.
		localStorage.setItem('last_asked_calendar_sync', new Date().toISOString()); // close modal
		close();
	};

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={
				page === 1 ? (
					<Text size={32} weight={500}>
						Connect your work calendar
					</Text>
				) : null
			}
			overlayProps={{
				opacity: 0.55,
				blur: 3,
			}}
			closeOnEscape={false}
			closeOnClickOutside={false}
			withCloseButton={false}
			trapFocus={false}
			radius={'lg'}
			styles={(theme) => ({
				header: {
					borderTopLeftRadius: theme.radius.lg,
					borderTopRightRadius: theme.radius.lg,
					paddingLeft: 32,
					paddingRight: 32,
				},
				body: {
					padding: 32,
					paddingBottom: theme.spacing.md,
				},
			})}
			size={660}
		>
			{page === 0 ? (
				<Stack align={'center'}>
					<Group noWrap position='apart' align='flex-start'>
						<Stack
							style={{
								flex: 3,
							}}
						>
							<Text size={32} weight={500}>
								Welcome to Reelay
							</Text>
							<Text>
								Complete the account setup to optimize your new meeting
								experience
							</Text>
						</Stack>
						<Image src={terms_icon} style={{ flex: 2 }} />
					</Group>

					<Divider w={'100%'} color='reelay-secondary-light' />

					<TimezoneSelect
						value={value}
						setValue={setValue}
						width={'100%'}
						label='Select your time zone'
					/>
					<TermsOfUseCheckbox
						checked={checked}
						setChecked={setChecked}
						width={'100%'}
					/>
					<Group
						position={'right'}
						w={`calc(100% + 32px + 32px)`}
						style={{
							borderTop: `1px solid ${theme.colors['reelay-secondary-light'][0]}`,
						}}
						pt={'md'}
					>
						<Button
							mr={32}
							radius={'md'}
							onClick={handleAgreeClick}
							disabled={!checked}
							loading={loading}
						>
							Continue
						</Button>
					</Group>
				</Stack>
			) : (
				<Stack align={'center'}>
					<Text>
						With Reelay connected to your calendar we'll be able to assist you
						in any Zoom, Teams, Meet, or WebEx calls of your choosing, next
						you'll select which calls reelay can automatically join.
					</Text>
					<Group noWrap position='apart'>
						{calendarIntegrationList.map((item, index) => (
							<IntegrationCard
								key={item.platform}
								title={calendarIntegrationList[index]['Title']}
								image={calendarIntegrationList[index]['image']}
								enabled={calendarIntegrationList[index]['enabled']}
								description={calendarIntegrationList[index]['description']}
								data={calendarIntegrationList[index]}
								noButton={true}
							/>
						))}
					</Group>
					<Group
						position={'apart'}
						w={`calc(100% + 32px + 32px)`}
						style={{
							borderTop: `1px solid ${theme.colors['reelay-secondary-light'][0]}`,
						}}
						pt={'md'}
						px={32}
					>
						<UnstyledButton onClick={() => setPage(0)} mr={32}>
							<Group noWrap position='apart' spacing={'xs'}>
								<FontAwesomeIcon icon={faLessThan} size='xs' />
								<Text>Go Back</Text>
							</Group>
						</UnstyledButton>
						<Button onClick={handleNotYet} variant='subtle'>
							Not yet
						</Button>
					</Group>
				</Stack>
			)}
		</Modal>
	);
}
