import {
	ActionIcon,
	Avatar,
	Box,
	Button,
	Center,
	Group,
	Paper,
	Progress,
	Stack,
	Text,
	Tooltip,
	createStyles,
} from '@mantine/core';
import { IMeeting } from '../../interfaces/meeting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faX } from '@fortawesome/pro-regular-svg-icons';
import {
	formatRelativeTime,
	formatRelativeTimeUpToYesterday,
} from '../../_utils/time';
import { swapBackgroundColor } from '../../_utils/handy-functions';
import { downloadAttachment } from '../../helpers/attachments';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { Dispatch, SetStateAction, useState } from 'react';
import { logger } from '../../helpers/logger';
import { destroyMeeting, getMeetingInvitedUsers } from '../../api/api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeetingDistributionList,
	meetingVideoUploadProgressBars,
} from '../../Atoms/meetingAtom';
import { StatusBadge } from '../../components/Badges/StatusBadge';
import { modals } from '@mantine/modals';
import { getEnvForGleap } from '../../_utils/trackers';
import { getMeetingHost } from '../../helpers/meetings';

// Define your styles using createStyles
const useStyles = createStyles((theme) => ({
	row: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
		height: '100%',
	},
	fileDetails: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		height: '100%',
	},
	fileSize: {
		marginLeft: 'auto',
	},
	iconWrapper: {
		// border: '0.5px solid #6938EF',
		border: '0.5px solid rgba(105, 56, 239, 0.5)',
		backgroundColor: '#FAFAFF',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '27px',
		padding: '8px', // Adjust padding as needed
	},
}));

interface Props {
	meeting: IMeeting;
	setMeetingToShare: Dispatch<SetStateAction<IMeeting | null>>;
	openMinutesModal: () => void;
	setMinutes: Dispatch<SetStateAction<IMeeting[]>>;
}

const PERCENT_LOADING = 100;

export default function MinutesCard({
	meeting,
	setMeetingToShare,
	openMinutesModal,
	setMinutes,
}: Props) {
	const { classes, theme } = useStyles();
	const {
		id,
		name,
		meetingDate,
		createdAt,
		publishedAt,
		organizationID,
		status,
		author,
		organizer,
		attachments,
	} = meeting;
	const setDistributionList = useSetRecoilState(currentMeetingDistributionList);
	const videoUploadProgressBars = useRecoilValue(
		meetingVideoUploadProgressBars
	);
	const isUploading =
		typeof videoUploadProgressBars[id] === 'number' &&
		videoUploadProgressBars[id] < PERCENT_LOADING;
	const [downloading, setDownloading] = useState(false);
	const [shareButtonLoading, setShareButtonLoading] = useState(false);

	const date = publishedAt
		? `Published: ${formatRelativeTimeUpToYesterday(publishedAt)}`
		: `Uploaded: ${formatRelativeTime(createdAt)}`;
	const label = getMeetingHost(organizer, author, 'Unknown Author');

	const minutesDoc = attachments.length > 0 ? attachments[0] : null;

	const statusLabel =
		status === 'published' || status === 'scheduled' ? status : 'processing';
	const host = author.id === organizer?.userID ? author : organizer;

	const handleShare = async () => {
		try {
			setShareButtonLoading(true);
			setMeetingToShare(meeting);
			const res = await getMeetingInvitedUsers(id, organizationID);
			const invitedUsers = res?.data?.data?.invitedUsers || [];
			const filteredDistributionList = invitedUsers.filter(
				(user) => !user?.userlink?.presented
			);
			setDistributionList(filteredDistributionList);
			openMinutesModal();
		} catch (err) {
			logger('error', 'Error sharing meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully shared. Please try the operation again.`,
			});
		} finally {
			setShareButtonLoading(false);
		}
	};

	const handleDownload = async () => {
		try {
			setDownloading(true);
			minutesDoc
				? downloadAttachment(minutesDoc, organizationID)
				: showFailureNotification({
						message: 'Sorry, no meeting minutes available for download.',
				  });
		} catch (err) {
			logger('error', 'Error downloading meeting minutes', err);
			showFailureNotification({
				message: `Apologies, the meeting minutes were not successfully downloaded. Please try the operation again.`,
			});
		} finally {
			setDownloading(false);
		}
	};

	const handleDeleteMeeting = async () => {
		modals.openConfirmModal({
			title: `Delete ${name}`,
			children: (
				<Text size='sm'>
					Are you sure you want to delete this meeting? This action is
					irreversible.
				</Text>
			),
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			onCancel: () => console.log('Cancel'),
			onConfirm: () => deleteMeeting(),
		});
	};

	const deleteMeeting = async () => {
		try {
			const res = await destroyMeeting(id, organizationID);
			showSuccessNotification({
				message: `Meeting was successfully deleted.`,
			});
			// update the meetings list
			const meetingID = res?.data?.data?.meetingIDs[0];
			setMinutes((prevMinutes) =>
				prevMinutes.filter((m) => m.id !== meetingID)
			);
		} catch (err) {
			logger('error', 'Error deleting meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully deleted. Please try the operation again.`,
			});
		}
	};

	return (
		<Paper
			withBorder
			radius={'md'}
			p={'xs'}
			shadow='sm'
			style={{ height: '100%' }}
			bg={isUploading ? 'white' : '#FAFAF9'}
			id={`VIS_ctf9nfdtmsxfgg2vmcm0_${getEnvForGleap()}`}
		>
			<Box key={id} className={classes.row}>
				<Group className={classes.fileDetails} noWrap>
					<Box className={classes.iconWrapper}>
						<FontAwesomeIcon icon={faCirclePlay} color='#6938EF' />
					</Box>
					<Stack spacing={0}>
						<Text size='sm' color='secondary-blue' fw={700} fz={14}>
							{name}
						</Text>
						<Text size='sm' color='secondary-blue' fw={700} fz={10}>
							{date}
						</Text>
					</Stack>
				</Group>
				<Group spacing='xs' noWrap miw={'279px'} position='apart'>
					<Group
						noWrap
						spacing={'xs'}
						style={{
							flex: 2,
						}}
					>
						<Tooltip label={label} withArrow>
							{host && 'userAvatarURL' in host && host.userAvatarURL ? (
								<Avatar
									src={swapBackgroundColor(
										`${host.userAvatarURL}-196.png`,
										theme.fn.primaryColor()
									)}
									radius='xl'
									size={34}
								/>
							) : (
								<Avatar
									color={'primary'}
									variant={'filled'}
									src={''}
									radius={'xl'}
									children={
										<Center>
											{host && host.firstName && host.lastName
												? `${host.firstName?.substring(
														0,
														1
												  )}${host.lastName?.substring(0, 1)}`.toUpperCase()
												: '?'}
										</Center>
									}
								/>
							)}
						</Tooltip>
						{status === 'published' ? (
							<>
								<Button
									onClick={handleDownload}
									loading={downloading}
									id={`VIS_ctf9nfdtmsxfgg2vmcp0_${getEnvForGleap()}`}
								>
									Download
								</Button>
								<Button
									onClick={handleShare}
									style={{ backgroundColor: '#6B7280' }}
									loading={shareButtonLoading}
									id={`VIS_ctf9nfdtmsxfgg2vmcpg_${getEnvForGleap()}`}
								>
									Share
								</Button>
							</>
						) : isUploading && videoUploadProgressBars[id] < PERCENT_LOADING ? (
							<Stack
								spacing={0}
								style={{
									flex: 2,
								}}
							>
								<Text fz={12} color='#6B7280' fw={600}>
									Uploading...
								</Text>
								<Progress
									radius='xl'
									size='lg'
									value={videoUploadProgressBars[id]}
									animate={videoUploadProgressBars[id] < PERCENT_LOADING}
								/>
							</Stack>
						) : (
							<StatusBadge status={statusLabel} />
						)}
					</Group>

					<ActionIcon
						onClick={handleDeleteMeeting}
						variant='transparent'
						color='secondary-blue'
						id={`VIS_ctf9nfdtmsxfgg2vmcqg_${getEnvForGleap()}`}
					>
						<FontAwesomeIcon
							icon={faX}
							color={theme.colors['secondary-blue'][0]}
						/>
					</ActionIcon>
				</Group>
			</Box>
		</Paper>
	);
}
