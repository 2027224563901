import React, { useState, useEffect } from 'react';
import {
	Container,
	TextInput,
	Button,
	ScrollArea,
	Text,
	Group,
	ActionIcon,
	Tooltip,
	createStyles,
	Title,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import Pusher from 'pusher-js';
import { massCalendarResync, resyncCalendars } from '../../api/recall';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { logger } from '../../helpers/logger';

const useStyles = createStyles((theme) => ({
	container: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: theme.spacing.xl,
	},
	inputSection: {
		marginBottom: theme.spacing.md,
	},
	scrollArea: {
		flex: 1,
	},
}));

// Define the type for log entries
type LogEntry = {
	calendarID: string;
	message: string;
	userID: string;
	platformEmail: string;
	organizationID: string;
	recallCalendarID: string;
};

export default function MassResyncPage() {
	const { classes } = useStyles();
	const [inputValue, setInputValue] = useState('');
	const [logs, setLogs] = useState<LogEntry[]>([]);
	const [loading, setLoading] = useState(false);

	const handleClick = async () => {
		setLoading(true);
		try {
			const res = await massCalendarResync(inputValue);
			console.log('res', res);
			setInputValue('');
			showSuccessNotification({
				message: 'Mass calendar resync started successfully.',
			});
		} catch (error) {
			logger('error', 'Error starting mass calendar resync', error);
			showFailureNotification({
				message: `Error: ${error.message}`,
			});
			setLogs((prevLogs) => [
				...prevLogs,
				{
					calendarID: 'error',
					message: `Error: ${error.message}`,
					userID: 'error',
					platformEmail: 'error',
					organizationID: 'error',
					recallCalendarID: 'error',
				},
			]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const supportChannel = window?.pusher?.subscribe(`reelay-support-users`);
		const handleCalendarSyncLogs = (data) => {
			setLogs((prevLogs) => [
				...prevLogs,
				{
					calendarID: data.calendarID,
					message: data.message,
					userID: data.userID,
					platformEmail: data.platformEmail,
					organizationID: data.organizationID,
					recallCalendarID: data.recallCalendarID,
				},
			]);
		};

		supportChannel.bind('calendar-sync-logs', handleCalendarSyncLogs);

		return () => {
			supportChannel.unbind('calendar-sync-logs', handleCalendarSyncLogs);
		};
	}, []);

	const retrySync = async (log: LogEntry) => {
		try {
			const res = await resyncCalendars(log.userID, log.organizationID);
			logger('info', `Resynced user ${log.userID} calendar`, res);
			showSuccessNotification({
				message: `Resynced user ${log.userID} calendar`,
			});
		} catch (error) {
			logger('error', `Error resyncing user ${log.userID} calendar`, error);
			showFailureNotification({
				message: `Error resyncing user ${log.userID} calendar`,
			});
		}
	};

	return (
		<Container className={classes.container}>
			<div className={classes.inputSection}>
				<TextInput
					value={inputValue}
					onChange={(event) => setInputValue(event.currentTarget.value)}
					placeholder='Enter secret key'
					description='Important: This will resync all the calendars for this environment'
					label='Secret key'
					disabled={loading}
				/>
				<Button
					onClick={handleClick}
					mt='md'
					color='red'
					loading={loading}
					disabled={loading}
				>
					Submit
				</Button>
			</div>
			<ScrollArea className={classes.scrollArea}>
				<Title order={3}>Logs</Title>
				{logs.map((log, index) => (
					<Group position='apart' noWrap key={index}>
						{log?.message?.toLowerCase().includes('failed') ? (
							<Text c='red'>{log.message}</Text>
						) : (
							<Text>{log.message}</Text>
						)}
						<ActionIcon variant='transparent' onClick={() => retrySync(log)}>
							<Tooltip label='Retry' position='top' withArrow>
								<FontAwesomeIcon icon={faRedo} />
							</Tooltip>
						</ActionIcon>
					</Group>
				))}
			</ScrollArea>
		</Container>
	);
}
