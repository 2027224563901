import {
	Button,
	Checkbox,
	CopyButton,
	Group,
	Modal,
	ScrollArea,
	Stack,
	Text,
	Textarea,
	createStyles,
	Select,
} from '@mantine/core';
import ModalTitle from '../../Titles/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendar,
	faLink,
	faLock,
	faPaperPlaneTop,
	faUserGroup,
} from '@fortawesome/pro-light-svg-icons';
import { IMeeting } from '../../../interfaces/meeting';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import { useForm } from '@mantine/form';
import {
	fetchMembers,
	flattenValidateAndFilterInvitees,
} from '../../../helpers/teams';
import {
	getUsersByOrg,
	inviteUsersToMeeting,
	removeUsersFromAMeeting,
	updateSharingTokenIsActive,
} from '../../../api/api';
import {
	User,
	currentUser,
	currentUserFormattedTeams,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DateInput } from '@mantine/dates';
import CustomCalendarDay from '../../CustomCalendarDay';
import { logger } from '../../../helpers/logger';
import {
	currentMeeting,
	currentMeetingDistributionList,
} from '../../../Atoms/meetingAtom';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import UserList from '../../UserList';
import {
	INVITE_ONLY,
	ANYONE_WITH_LINK,
} from '../../../helpers/settings/settings.constants';
import { organizationSettings } from '../../../Atoms/settings';

const useStyles = createStyles(() => ({
	dateInput: {
		borderRadius: '8px',
		fontSize: '12px',
		width: '120px',
		height: '40px',
	},
}));

interface Props {
	opened: boolean;
	close: () => void;
	organizationID: string;
}

export default function SharingModal({ close, opened, organizationID }: Props) {
	const { classes } = useStyles();
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const { name, id, organizer, owningUserID } = meeting;
	const user = useRecoilValue(currentUser);
	const orgMembers = useRecoilValue(userOrganizationMembers);
	const [distributionList, setDistributionList] = useRecoilState(
		currentMeetingDistributionList
	);
	const teams = useRecoilValue(currentUserFormattedTeams);
	const [teamData, setTeamData] = useState<
		{ value: string | string[]; label: string; [key: string]: any }[]
	>([]);
	const [expirationDate, setExpirationDate] = useState<Date | null>(null);
	const [message, setMessage] = useState('');
	const [buttonLoading, setButtonLoading] = useState(false);
	const [notifyPeopleChecked, setNotifyPeopleChecked] = useState(true);

	const { origin } = window.location;
	let copyLinkValue = `${origin}/meetings/${meeting.id}`;
	if (meeting?.sharingToken?.active) {
		copyLinkValue += `?vt=${meeting.sharingToken.token}`;
	}

	const distributionListSorted = [...distributionList].sort((a, b) => {
		// Check if either user is the meeting owner and prioritize them
		if (meeting.owningUserID === a.id) return -1; // a is the meeting owner, place a before b
		if (meeting.owningUserID === b.id) return 1; // b is the meeting owner, place b after a

		// If neither user is the meeting owner, sort chronologically by createdAt
		const dateA = new Date(a.userlink.createdAt).getTime();
		const dateB = new Date(b.userlink.createdAt).getTime();

		return dateA - dateB; // Sorts ascending; for descending, swap dateB and dateA
	});

	const remainingTeamData = teamData.filter((user) => {
		return !distributionList.find((item) => item.id === user.id);
	});

	const form = useForm({
		initialValues: {
			viewers: [],
		},
		validate: {
			viewers: (value) =>
				value.length ? null : 'At least one viewer is required.',
		},
	});

	const userData = orgMembers
		.map((user: User) => ({
			...user,
			label: user.email,
			value: user.email,
			key: user.id,
		}))
		.filter((item: User, index, array) => {
			return (
				user.id !== item.id &&
				array.findIndex((i) => i.id === item.id) === index
			);
		});

	const { sharing: sharingEnabled } = useRecoilValue(organizationSettings);

	useEffect(() => {
		const fetchMembersData = async () => {
			if (organizationID) {
				const users = userData.map((user: User) => {
					const { id, email, firstName, lastName } = user;
					const label = `${firstName || ''} ${
						lastName || ''
					} (${email})`.trim();

					return {
						...user,
						label,
						value: email,
						key: id,
					};
				});
				const members = await fetchMembers(users, organizationID);
				setTeamData(members);
			}
		};
		fetchMembersData();
	}, [organizationID]);

	useEffect(() => {
		if (opened) setNotifyPeopleChecked(true);
		else setMessage('');
	}, [opened]);

	const changeMeetingSharing = async (value: string) => {
		try {
			const isActive = value === ANYONE_WITH_LINK;
			const {
				data: { data: sharingToken },
			} = await updateSharingTokenIsActive(
				{
					targetID: meeting.id,
					value: isActive,
				},
				organizationID
			);
			setMeeting((prev) => ({
				...prev,
				sharingToken,
			}));
			showSuccessNotification({
				message:
					'You have successfully updated the sharing setting of this meeting.',
			});
		} catch (error) {
			logger('error', 'error updating sharing token of a meeting', error);
			showFailureNotification({
				message:
					'Sorry, there was an error updating the sharing setting of the meeting. Please try again.',
			});
		}
	};

	const sendInvites = async () => {
		try {
			setButtonLoading(true);
			const teamMembers = {};

			teamData.forEach((item) => {
				if (item?.email) teamMembers[item.email] = item;
			});
			const emails = flattenValidateAndFilterInvitees(form.values.viewers);
			const members = [];
			const guests = [];

			emails.forEach((email) => {
				if (teamMembers[email]) {
					members.push(email);
				} else {
					guests.push(email);
				}
			});

			// invite invitees to meet by members or guests. if they are a part of the organization already, then 'member' else 'guest'
			let membersResponse;
			if (members.length)
				membersResponse = await inviteUsersToMeeting({
					emails: members,
					meetingID: id,
					organizationID,
					roles: ['member'],
					expirationDate,
					emailMessage: message,
					sendEmail: notifyPeopleChecked,
				});
			let guestsResponse;
			if (guests.length)
				guestsResponse = await inviteUsersToMeeting({
					emails: guests,
					meetingID: id,
					organizationID,
					roles: ['guest'],
					expirationDate,
					emailMessage: message,
					sendEmail: notifyPeopleChecked,
				});
			const invitedUsers = [
				...(Array.isArray(membersResponse?.data?.data)
					? membersResponse.data.data
					: []),
				...(Array.isArray(guestsResponse?.data?.data)
					? guestsResponse.data.data
					: []),
			];
			logger('info', 'users added to meeting', invitedUsers);
			setDistributionList((prev) => {
				// search for existing users in DB.
				// map then filter for duplicates
				const result = [...prev, ...invitedUsers].filter(
					(item, index, array) => {
						return array.findIndex((i) => i.id === item.id) === index;
					}
				);
				return result;
			});
			showSuccessNotification({
				title: 'Success!',
				message: 'Viewers were successfully added. Great work!',
			});
			form.reset();
			setNotifyPeopleChecked(false);
			close();
		} catch (error: unknown) {
			logger('error', 'failed to invite users to meeting', error);
			showFailureNotification({
				message: 'Sorry, failed to invite users to meeting. Please try again.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	const handleRemoveUser = async (email: string) => {
		if (!email) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to remove the user from the meeting. Please give it another try.',
			});
			return;
		}
		try {
			logger('info', 'remove user from meeting', {
				meetingID: id,
				organizationID,
				email,
			});
			const res = await removeUsersFromAMeeting([email], id, organizationID);
			const newInvitedUsers = res?.data?.data?.invitedUsers || [];
			setDistributionList(newInvitedUsers);
			showSuccessNotification({
				message: 'User successfully removed from the meeting.',
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to remove the user from the meeting. Please give it another try.',
			});
			logger('error', 'error removing user from meeting:', {
				meetingID: id,
				organizationID,
				email,
				error,
			});
		}
	};

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={
				<ModalTitle
					text={`Share`}
					icon={<FontAwesomeIcon icon={faUserGroup} />}
				/>
			}
			size={'lg'}
			zIndex={10000}
			radius={'lg'}
			styles={(theme) => ({
				header: {
					borderRadius: theme.spacing.lg,
				},
			})}
		>
			<Stack>
				<Group noWrap position='apart' spacing={'xs'}>
					<Text>
						Share{' '}
						<Text span fw={600}>
							{name}
						</Text>{' '}
						Recording
					</Text>
					<CopyButton value={copyLinkValue}>
						{({ copied, copy }) => (
							<Button
								fw={400}
								onClick={copy}
								variant='subtle'
								leftIcon={<FontAwesomeIcon icon={faLink} />}
							>
								{copied ? 'Copied Link' : 'Copy Link'}
							</Button>
						)}
					</CopyButton>
				</Group>
				<UsersMultiSelect
					// autoFocus={true}
					creatable={true}
					descriptionText={'Add Viewers'}
					variantType={'filled'}
					data={remainingTeamData}
					setData={setTeamData}
					clearable={false}
					searchable={true}
					labelText={''}
					placeholder='Add a name, email, or team.'
					name={'viewers'}
					form={form}
				/>

				{form.values.viewers.length ? (
					<Stack>
						<DateInput
							minDate={new Date()}
							clearable
							placeholder='Optional - Expires: mm/dd/yyyy'
							className={classes.dateInput}
							variant='filled'
							valueFormat='MM/DD/YYYY'
							// {...form.getInputProps('date')}
							renderDay={(date) => <CustomCalendarDay date={date} />}
							value={expirationDate}
							onChange={setExpirationDate}
							w={'100%'}
							icon={<FontAwesomeIcon icon={faCalendar} />}
						/>
						<Group noWrap position='left'>
							<Checkbox
								checked={notifyPeopleChecked}
								onChange={(event) =>
									setNotifyPeopleChecked(event.currentTarget.checked)
								}
								label='Notify People'
							/>
						</Group>
					</Stack>
				) : (
					<ScrollArea
						styles={{
							viewport: {
								maxHeight: '350px',
							},
						}}
						type='always'
						h={'auto'}
						mah={350}
						offsetScrollbars
					>
						<Stack spacing={'sm'}>
							<Text>People with access</Text>
							<UserList
								searchValue={''}
								users={distributionListSorted}
								gridColSpan={12}
								emptyText={'You have no viewers to manage.'}
								handleRemoveUser={handleRemoveUser}
								showLinkedIn={false}
								organizer={organizer}
								owningUserID={owningUserID}
							/>
						</Stack>
					</ScrollArea>
				)}

				{notifyPeopleChecked && form.values.viewers.length ? (
					<Textarea
						placeholder='Add a message'
						minRows={5}
						value={message}
						onChange={(event) => setMessage(event.currentTarget.value)}
					/>
				) : null}

				<Group noWrap position='apart' spacing={'xs'}>
					<Select
						radius={'xl'}
						value={
							meeting?.sharingToken?.active ? ANYONE_WITH_LINK : INVITE_ONLY
						}
						disabled={!sharingEnabled}
						onChange={changeMeetingSharing}
						data={[
							{ label: 'Invite Only', value: INVITE_ONLY },
							{ label: 'Anyone With Link', value: ANYONE_WITH_LINK },
						]}
						placeholder='Select Sharing Option'
					/>
					<Button
						radius={'md'}
						leftIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
						disabled={form.values.viewers.length === 0}
						onClick={sendInvites}
						loading={buttonLoading}
					>
						{notifyPeopleChecked ? 'Send' : 'Share'}
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
